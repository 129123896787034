<template>
  <b-container class="pt-2" fluid="sm">

    <h3 class="mt-4">Contrats</h3>

    <form ref="form">
      <b-form-row>
        <b-col>
          <b-form-group
            label="Rechercher un Contrat"
            label-for="search"
            invalid-feedback="min 3 caratères"
            required                          
            :state="searchState"
          >
          <b-input-group-append>
            <b-form-input id="search" v-model="keyword" @keyup="searchContract()"></b-form-input>
          </b-input-group-append>
          </b-form-group>
        </b-col>

        <b-col class="d-flex align-items-end">
          <b-form-group>
            <b-button @click="openModalContract(0)" variant="primary"> Nouveau </b-button>
         
          </b-form-group>
        </b-col>

      </b-form-row>
    </form>

    <b-form >
      <b-form-row>
        <b-col sm="3" md="5">
          <label for="inline-form-input-name">Debut</label>
          <b-form-datepicker id="beginDate" v-model="beginDate" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                locale="fr" class="mb-2" @input="saveForm"></b-form-datepicker> 
        </b-col>
        <b-col sm="3" md="5">
          <label for="inline-form-input-username">Fin</label>
          <b-form-datepicker id="endDate" v-model="endDate" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
          locale="fr" class="mb-2" @input="saveForm"></b-form-datepicker>
        </b-col >
        <b-col sm="3" md="2" class="d-flex align-items-end" style="margin-bottom:8px">
          <b-button @click="loadContracts(beginDate,endDate, signed)" variant="primary">Rechercher</b-button>
        </b-col>

      </b-form-row>
      <b-form-row>
          <b-col>
            <b-form-checkbox v-model="signed" name="check-button" switch  @change="loadContracts(beginDate,endDate, signed)"> Signés
           </b-form-checkbox>
          </b-col>
        </b-form-row>
  </b-form>

    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-card>
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
      </template>
      

    <div>

    <b-card>
      <b-list-group v-if="filtreContracts.length > 0">
        <b-list-group-item v-for="contract in filtreContracts" :key="contract.id" class="flex-column align-items-start">

          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">{{contract.customer.lastname}} {{contract.customer.firstname}}</h5>
            <small><b-badge variant="info" v-if="contract.is_signed =='YES'">Signé</b-badge></small>
          </div>

          <!-- <div class="d-flex w-100 justify-content-between">
            <div>
              <p class="mb-1"><b-icon icon="globe"></b-icon> :{{contract.address.address1}}, {{contract.address.postcode}}, {{contract.address.city}} </p>
              <p class="mb-1"><b-icon icon="telephone"></b-icon> : <span>{{contract.customer.phone}}</span></p>
              <p class="mb-1"><b-icon icon="phone"></b-icon> : <span>{{contract.customer.mobile}}</span></p>
              <p class="mb-1"><b-icon icon="envelope"></b-icon> :<span> {{contract.customer.email}}</span></p>
            </div>
            <div class="d-flex align-items-center">
              <b-dropdown variant="outline-primary" right text="Actions">
              <b-dropdown-item v-if="contract.is_hold_quote !='YES'" @click="openModalContract(contract.id)">Modifier</b-dropdown-item>
              <b-dropdown-item @click="routeTo(contract.customer_id, contract.id)" >Créer un Devis </b-dropdown-item>
              <b-dropdown-item @click="routeToDocs(contract)">Documents</b-dropdown-item>
            </b-dropdown>
            </div>
          </div> -->

          <b-row>
            <b-col>
              <div  style="font-size: 0.8rem;">
                <p class="mb-1"><b-icon icon="globe"></b-icon> :{{contract.address.address1}}, {{contract.address.postcode}}, {{contract.address.city}} </p>
                <p class="mb-1"><b-icon icon="telephone"></b-icon> : <span>{{contract.customer.phone}}</span></p>
                <p class="mb-1"><b-icon icon="phone"></b-icon> : <span>{{contract.customer.mobile}}</span></p>
                <p class="mb-1"><b-icon icon="envelope"></b-icon> :<span> {{contract.customer.email}}</span></p>
              </div>
            </b-col>
            <b-col class="d-flex align-items-center justify-content-end">
              <b-dropdown variant="outline-primary" right text="Actions">
                <b-dropdown-item v-if="contract.is_hold_quote !='YES'" @click="openModalContract(contract.id)">Modifier</b-dropdown-item>
                <b-dropdown-item @click="routeTo(contract.customer_id, contract.id)" >Créer un Devis </b-dropdown-item>
                <b-dropdown-item @click="routeToDocs(contract)">Documents</b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
      <b-card-body v-else>
          Aucun contrat
        </b-card-body>
    </b-card>
    </div>

  </b-skeleton-wrapper>

   <!-- Modale -->
    <b-modal
        id="modal-contract" ref="modal"
        :title="modal_title"
        okTitle= 'Envoyer'
        cancelTitle= 'Annuler'
        header-bg-variant="primary"
        header-text-variant="white"
        size="lg"
        @show="resetModalContract"
        @hidden="resetModalContract"
        @ok="handleContractOk"
      >
      
      <validation-observer ref="observer" v-slot="{ handleContractOk }">
        <!-- Tabs with card integration -->
          <b-form ref="formRDV" @submit.prevent="handleContractOk()" @reset="onReset">
            <b-form-row>
              <b-col md="12">
                <b-card title="Contact" class="mb-3" >     
                    <b-form-row>
                        <b-col md="12">
                          <b-form-group label="Genre" label-for="gender">
                            <b-form-radio-group button-variant="outline-primary" 
                              id="gender"
                              name="gender"
                              v-model="contract.customer.gender"  
                              :options="['Mr','Mme']"
                              >
                            </b-form-radio-group>
                          </b-form-group>
                        </b-col>
                      </b-form-row>            
                      <b-form-row >
                      <b-col md="6">
                        <TextInput id="nom" type="text" label="Nom" :rules="{ required: true, min: 3 }" v-model="contract.customer.lastname"></TextInput>
                      </b-col>
                      <b-col md="6">
                        <TextInput id="prenom" type="text" label="Prénom" :rules="{ required: true, min: 3 }" v-model="contract.customer.firstname"></TextInput>
                      </b-col>
                    </b-form-row>
                    <b-form-row >
                      <b-col md="12">
                        <TextInput id="adresse" type="text" label="Adresse" :rules="{ required: true, min: 3 }" v-model="contract.address.address1"></TextInput>             
                      </b-col>
                    </b-form-row>
                    <b-form-row>            
                      <b-col md="3">
                        <TextInput id="codePostal" type="number" label="Code postal" :rules="{ required: true, min: 5 , max:5}" v-model="contract.address.postcode"></TextInput>             
                      </b-col>
                      <b-col md="9">
                        <TextInput id="ville" type="text" label="Ville" :rules="{ required: true, min: 3 }" v-model="contract.address.city"></TextInput>             
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col md="6">
                        <TextInput id="email" type="email" label="Email" :rules="{ required: true, email: true}" v-model="contract.customer.email"></TextInput>             
                      </b-col>
                      <b-col md="6">
                        <TextInput id="telephone" type="tel" label="Tél." :rules="{ required: false, min: 10, max:10 }" v-model="contract.customer.phone"></TextInput>             
                      </b-col>
                      <b-col md="12">
                        <TextInput id="mobile" type="tel" label="Mobile" :rules="{ required: true, min: 10 , max: 10}" v-model="contract.customer.mobile"></TextInput>                 
                      </b-col>
                    </b-form-row>
                    <!-- <b-form-row>            
                      <b-col md="6">
                        <validation-provider
                                name="Energie"
                                :rules="{ required: true}"
                                v-slot="validationContext"
                        >
                        <b-form-group label="Energie" label-for="energy">
                          <b-form-select 
                            id="energy" 
                            v-model="contract.energy_id"  
                            :options="energyList" 
                            :state="getValidationState(validationContext)" 
                            aria-describedby="energy-feedback">
                          </b-form-select>
                          <b-form-invalid-feedback id="energy-feedback" :state="getValidationState(validationContext)" >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>    
                        </validation-provider>                      
                      </b-col>
                      <b-col md="6">
                        <TextInput id="surface" type="number" label="Surface de la maison" :rules="{ required: true, min: 1 , max: 1000}" v-model="contract.surface_ite"></TextInput>                 
                      </b-col>
                    </b-form-row> -->
                    <!-- <b-form-row>
                      <b-col md="12">
                        <b-form-checkbox v-model="memeAdresse" name="check-button" switch @click="memeAdresse = !memeAdresse">
                          Meme adresse
                        </b-form-checkbox>            
                      </b-col>                     
                    </b-form-row> -->
                </b-card>
              </b-col>             
            </b-form-row>
          </b-form>
      </validation-observer>     
    </b-modal>
  </b-container>
</template>

<script>
import ContractService from '../services/ContractService'
import TextInput from '../components/TextInput.vue'

export default {
  name: 'MContracts',
  components: {
    TextInput
  },
  data: () => ({
    beginDate: localStorage.getItem('contractBeginDate') || new Date().toISOString().split('T')[0],
    endDate: localStorage.getItem('contractEndDate') || new Date().toISOString().split('T')[0],
    signed : localStorage.getItem('contractSigned') ||false,
    message : '',
    energyList : [],
    loading : false,
    contract : null,
    contracts: [],
    filtreContracts : [],
    keyword :'',
    dateState : '',
    dateRDV: '',
    dateNaissanceState: '',
    heureState: '',
    notes:'',
    searchState: null,
    articleState : null,
    contract_extra : null,
    modal_title : "Nouveau contrat"
    
  }),
  async mounted(){
    try {
      await this.loadContracts( this.beginDate, this.endDate)     
    } catch (error) {
      console.log(error)
    }

    // try {
    //   await this.loadEnergyList()     
    // } catch (error) {
    //   console.log(error)      
    // }
  },
  computed : {
    modelVT() {
      return this.$store.getters.modelVT
    },
  },
  watch: {
    informations: {
      handler(){
        this.$refs.observer.validate().then(success => {
          //this.showTabs = success

          if(success) {
            //this.$store.dispatch("setContract", this.informations)
          }
        })
      },
      deep: true
    },
  },

  methods: {
    saveForm() {
      localStorage.setItem('contractBeginDate', this.beginDate);
      localStorage.setItem('contractEndDate', this.endDate);
      localStorage.setItem('contractSigned', this.signed);
    },
    onSubmit (evt) {
      evt.preventDefault()
      alert(JSON.stringify(this.form))
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    newContract () {
      return {
        id: 0,
        opened_at: null,
        team_id: 0,
        telepro_id: 0,
        sale_1_id: 0,
        sale_2_id: 0,
        manager_id: 0,
        opened_at_range_id: null,
        sav_at_range_id: null,
        is_signed: null,
        quoted_at: null,
        billing_at: null,
        polluter_id: 106,
        more_2_years : "YES",
        revenue : 1000,
        pricing_id : 5,
        energy_id: 1,
        occupation_id : 1,
        layer_type_id : 1,
        is_confirmed: null,
        opc_range_id: null,
        sav_at: null,
        opc_at: null,
        assistant_id: "0",
        pre_meeting_at: null,
        customer: {
          "id": 0,
          "gender": "Mr",
          "firstname": null,
          "lastname": null,
          "email": null,
          "phone": null,
          "mobile": null,
          "phone1": null,
          "birthday": null,
          "salary": null,
          "mobile2": null,
          "company": null,
          "union_id": null,
          "age": null,
          "occupation": null,
          "created_at": null,
          "updated_at": null,
          "status": null
      },
      address: {
          "id": 0,
          "customer_id": 0,
          "address1": null,
          "address2": null,
          "postcode": null,
          "city": null,
          "country": null,
          "state": null,
          "status": null,
          "created_at": null,
          "updated_at": null
      }
    }
    },
    async loadEnergyList() {
      try {
        this.loading= true;
        let result = await ContractService.ListEnergy()
          this.loading = false;
          if(result.status == 200) {
            this.energyList = Object.entries(result.data).map(entry => {return {value:entry[0], text: entry[1]}})
          }
      } catch (error) {
        this.loading = false;
          this.$bvModal.msgBoxOk(`Impossible de charger la liste des energies : ${error.message}`, {
            title: 'Erreur',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
      }
  
    },
    async loadContracts(beginDate, endDate, signed){
      localStorage.setItem('contractSigned', signed);
      try {
        this.loading= true;
        let result = await ContractService.ListContract(beginDate, endDate, signed)
          this.loading = false;
          if(result.status == 200) {

            this.totalRows = result.data.number_of_items
            this.contracts = result.data.data
            this.filtreContracts = JSON.parse(JSON.stringify(this.contracts))
          }
      } catch (error) {
        this.loading = false;
          this.$bvModal.msgBoxOk(`Impossible de charger la liste des contracts : ${error.message}`, {
            title: 'Erreur',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
      }
  
    },
    async getContract(id){
      try {
        let result = await ContractService.GetContract(id)
        if(result.status == 200) {
          this.contract_extra = result.data.data
        }
      } catch (error) {
         this.$bvModal.msgBoxOk(`Impossible de charger le contrat: ${error}`, {
           title: 'Erreur',
           okVariant: 'danger',
           headerClass: 'p-2 border-bottom-0',
           footerClass: 'p-2 border-top-0',
           centered: true
         })
      }
  
    },
    searchContract(){
      if(this.keyword.length < 3) {
        this.filtreContracts = JSON.parse(JSON.stringify(this.contracts))
        return
      }

      this.filtreContracts = this.contracts.filter(
        x=> x.customer.firstname.toUpperCase().includes(this.keyword.toUpperCase())
      )
    },
    checkFormContractValidity() {
        let valid = this.$refs.formRDV.checkValidity()

        if(!this.contract.in_at_date)
          this.dateState = valid
        else
          this.dateState = true

      
        return valid
    },
    resetModalContract() {
      this.dateState = null
    },
    openModalContract(id) {
      if(id != 0) {
        this.modal_title = "Modifier"
        this.contract = {... this.contracts.find(x=>x.id == id)}
      }else {
        this.modal_title = "Nouveau contrat"
        this.contract = this.newContract()
      }
      this.$bvModal.show(`modal-contract`)
    },
    routeTo(customer_id, contract_id) {
      console.log(`/devis/contract/${customer_id}/${contract_id}`)
      this.$store.dispatch('setContract', this.contracts.find(x=>x.id == contract_id))
      this.$store.dispatch('setMeeting', null)
      this.$router.push(`/devis/contract/${customer_id}/${contract_id}`)
    },

    routeToDocs(contract) {
      this.$store.dispatch('setContract', contract)
      this.$router.push(`/contract/${contract.id}`)
    },
    handleContractOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.$refs.observer.validate().then(success => {
          if(success) {
            // Trigger submit handler
            this.handleSubmitContract()
          }
        })

    },
    async handleSubmitContract() {
      // Exit when the form isn't valid

        let result = null
         try {


          if(this.contract.id == 0) {
            result = await ContractService.NewContract(this.contract)
          }
          else {
            result = await ContractService.GetContract(this.contract.id)
            if(result.status != 200 || result.data.errors) {
              this.$bvModal.msgBoxOk(`Impossible d'enregistrer le contrat 1 : ${JSON.stringify(result.data.errors)}`, {
                title: 'Erreur',
                okVariant: 'danger',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
              })
              return
            }else {
              this.contract_extra = result.data.data
            }
        

            this.contract.number_of_people = this.contract_extra.requests.number_of_people
            this.contract.number_of_parts = this.contract_extra.requests.number_of_parts
            this.contract.declarants = this.contract_extra.requests.declarants
            this.contract.number_of_fiscal = this.contract_extra.requests.number_of_fiscal    
            this.contract.pre_meeting_at_date = this.contract_extra.pre_meeting_at ? this.contract_extra.pre_meeting_at.split(' ')[0] : "2024-01-01"
            this.contract.pre_meeting_at_hour = this.contract_extra.pre_meeting_at ? this.contract_extra.pre_meeting_at.split(' ')[1].substring(0,2) : "12"
            this.contract.pre_meeting_at_minute = this.contract_extra.pre_meeting_at ? this.contract_extra.pre_meeting_at.split(' ')[1].substring(3,5) : "00"
            this.contract.quoted_at = this.contract_extra.quoted_at
            this.contract.more_2_years = this.contract_extra.requests.more_2_years
            this.contract.revenue = this.contract_extra.requests.revenue
            this.contract.energy_id = this.contract_extra.requests.energy_id
            this.contract.occupation_id = this.contract_extra.requests.occupation_id
            this.contract.layer_type_id = this.contract_extra.requests.layer_type_id
            this.contract.pricing_id = this.contract_extra.requests.pricing_id
            this.contract.surface_home = this.contract_extra.requests.surface_home
            this.contract.surface_ite = this.contract_extra.requests.surface_ite

            result = await ContractService.UpdateContract(this.contract)
          }

          if(result.status != 200 || result.data.errors) {
            this.$bvModal.msgBoxOk(`Impossible d'enregistrer le contrat 2 : ${JSON.stringify(result.data.errors)}`, {
              title: 'Erreur',
              okVariant: 'danger',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
            return
          }   
          
          this.$bvModal.msgBoxOk('Contrat enregistré avec succès', {
              title: 'Confirmation',
              okVariant: 'success',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
          await this.loadContracts( this.beginDate, this.endDate) 

        } catch (error) {
          this.$bvModal.msgBoxOk(`Impossible d'enregistrer le contrat : ${error.message}`, {
              title: 'Erreur',
              okVariant: 'danger',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
        }
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-contract')
        })
    },
    onReset (evt) {
      evt.preventDefault()
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
/* ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
} */
legend {
  font-size: 16px;
}

.contact-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .contact-section {
      flex: 1;
      margin-right: 20px; /* Espacement entre les sections */
    }

    .contact-section:last-child {
      margin-right: 0; /* Supprime la marge à droite de la dernière section */
    }

    /* Style pour les étiquettes */
    .label {
      font-weight: bold;
    }


</style>
