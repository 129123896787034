<template>
  <b-container class="pt-2" fluid="sm">
    <div class="d-flex flex-row mt-4  justify-content-start">
      <go-back/> <h3>Devis</h3>
    </div>

    <b-card v-if="contract" title="" class="mb-3">    
        {{ contract.customer.firstname }} {{ contract.customer.lastname }}
    </b-card>

    <b-card v-if="meeting" title="" class="mb-3">    
        {{ meeting.customer.firstname }} {{ meeting.customer.lastname }}
    </b-card>

    <b-card no-body>
      <b-tabs v-model="tabIndex" small card pills>
          <validation-observer ref="observer_elegible" v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset="onReset">

          <!--<b-tab  v-for="bloc in blocs" :key="bloc.id" :title="bloc.title" :title-link-class="linkClass(blocs.id)" @click="selectedTab=bloc.id"-->
            <BlocItem v-for="bloc in blocs" :key="bloc.id" :bloc="bloc" :selectedTab="selectedTab" @clicked="onClicked"></BlocItem>
          <!--/b-tab-->
           </b-form>
            </validation-observer>
          <b-form-row v-if="(tabIndex==0 || tabIndex==1) && (modelVT.isEligibleLuminaire || modelVT.isEligibleThermostatique)">
            <b-col class="mt-3 mb-3" md="3" offset-md="9" align-self="center">
              <b-button variant="primary" @click="update()">Enregistrer</b-button>
            </b-col>
          </b-form-row>

          <b-tab v-if="isLoaded" title="Commande" :title-link-class="linkClass('commande')" @click="selectedTab='commande'">
            <b-form-row>
              <b-col md="12">
                <b-alert variant="danger" :show="!isEligible">Non eligible</b-alert>
               
                  <form ref="form" @submit.stop.prevent="handleSubmit" v-if="isEligible">
                <div v-if="kits.length > 0">
                  <b-card v-for="(kit, index) in kits" :key="index" class="mb-3">
                    <template v-slot:header>
                      <div class="d-flex justify-content-between align-items-center">
                        <h6 class="mb-0">{{kit.reference}}</h6>
                      </div>
                    </template>
                    <b-list-group flush class="d-flex" >
                      <b-list-group-item ref='selectedPacks' class="justify-content-between align-items-center">
                        <b-row>
                          <b-col sm="3">REF. :{{kit.input4}}</b-col>
                          <b-col sm="2">QTE. : 1 </b-col>
                          <b-col sm="2">HT : {{ montant_HT | separators}}</b-col>
                          <b-col sm="3">TVA {{ modelVT.tva }} % : {{montant_TVA | separators}}</b-col>
                          <b-col sm="2">TTC : {{montant_TTC | separators}}</b-col>     
                        </b-row>
                      </b-list-group-item>
                      <b-list-group-item class="justify-content-between align-items-center">
                        <b-row>
                          <div v-html="kit.description"></div>
                        </b-row>
                      </b-list-group-item>
                      <b-list-group-item class="justify-content-between align-items-center">
                        <b-row>
                          <div>Prime CEE : {{ montant_primeCEE | separators}}</div>
                        </b-row>
                      </b-list-group-item>
                    </b-list-group>
                  </b-card>
                </div>

              </form>
              </b-col>
            </b-form-row>
          </b-tab>
        
          <b-tab v-if="isLoaded" title="Devis" :title-link-class="linkClass('devis')" @click="selectedTab='devis'">
            <b-form-row>
              <b-col md="12">
                <CreationDevis :article="kit" :origine="$route.params.origine" :quotation="quotation"/>
              </b-col>
            </b-form-row>
          </b-tab>
        </b-tabs>
      </b-card>
  </b-container>
</template> 

<script>

import BlocItem from '../components/BlocItem'
import GoBack from '../components/GoBack'
import CreationDevis from '../components/CreationDevis'
import CatalogueService from '../services/CatalogService'
import MeetingService from '../services/MeetingService'
import ContractService from '../services/ContractService'
import FidealisService from '../services/FidealisService'

export default {
  name: 'MDossier',
  components: {
    //TextInput,
    CreationDevis,
    BlocItem,
    GoBack,


  },
  data: () => ({
      tabIndex: 0,   
      selectedTab: "info",
      loading : false,
      show: true,
      blocs : [],
      quotation: null,
      isLoaded: false,
      observations: "",
      cumac: 80000,
      kits : [],
      kit : null,
      articles: [],
      all_articles : [],
      extra : {}
  }),
  async mounted(){
    console.log(this.$route.params)
    //this.$store.dispatch('fetchModelVT').then(() => {})
    this.modelVT.reset()
    this.blocs = this.modelVT.blocs

    console.log("VT",this.blocs)


    try {
      const result = await CatalogueService.GetArticles()
      if(result.status == 200) {
        this.all_articles = result.data.data
      }
    this.isLoaded = true

  
    } catch (error) {
      this.loading = false
      this.$bvModal.msgBoxOk(`Error : ${error.message}`, {
              title: 'Erreur',
              okVariant: 'danger',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
    }


    if(this.$route.params.mode == "update" ||  this.$route.params.mode == "new"){
      console.log(this.$route.params.mode)
        //getMeeting       
        await this.getMeeting()
        
        // get list yousign

        // map form

        // go to form


    }
    
  },
  watch: {
    'modelVT.typeChauffage': function(newValue) {
      if (newValue !== null) {
        this.modelVT.chauffageAccumulation = null
        this.modelVT.vannes = null;

      }
    },
    modelVT: {
      handler(newValue){
        if(newValue.isEligibleThermostatique || newValue.isEligibleLuminaire) {
          this.makeOrder()
        }else {
          this.articles = []
        }

        this.$store.dispatch("setModelVT", newValue)
      },
      deep: true
    }
  },
  computed: {
    origine () {
      return this.$route.meta.origine
    },
    quantiteTotal() {
      return this.articles.reduce((sum, current) => {
          return sum + Number(current.quantite);
        },0)
    },
    totalTTC() {
      return this.articles.reduce((sum, current) => {
          return sum + current.totalTTC;
        },0)
    },
    modelVT() {
      return this.$store.getters.modelVT
    },
    rules() {
      return this.$store.getters.rules
    },
    meeting() {
      return this.$store.getters.meeting
    },
    contract() {
      return this.$store.getters.contract
    },
    isEligible() {
      return this.modelVT.isEligibleLuminaire || this.modelVT.isEligibleThermostatique
    },
    vannes() {
      return this.modelVT.vannes
    },
    chauffageAccumulation() {
      return this.modelVT.chauffageAccumulation 
    },
    montant_TTC() {
        return this.modelVT.facteurCorrectif * 520
    },
    montant_HT() {
        return this.montant_TTC / (1+ this.modelVT.tva /100)
    },
    montant_TVA() {
        return this.montant_TTC - this.montant_HT
    },
    montant_primeCEE() {
      return this.montant_HT + this.montant_TVA
    },
    volumeCumac() {
        return this.modelVT.facteurCorrectif * this.cumac
    },
    valorisationCumac() {
      return (this.volumeCumac * 7600) / 1000000
    }
  },
  methods: {
      makeOrder () {
        console.log("makeOder")
        this.articles = []
        this.kits = []
        this.kit = null
        this.modelVT.rules = this.rules

        const id = this.modelVT.findProductId()
        console.log(id)
        this.kit = {... this.all_articles.find(x=> x.id == id)}


        //remplace les \r\n par des \br
        // $SURFACE 
        // $X
        // $CHAUFFAGE


        if(this.kit.description) {
          this.kit.sale_price = this.montant_HT
          //this.kit.fk_taux_tva = this.modelVT.tva == "10" ? 5 : 2 
          this.kit.description = this.kit.description.replace(/(?:\r\n|\r|\n)/g, '<br>')
          this.kit.description = this.kit.description.replaceAll("{$contract.forms.GES.Nombrederadiateurs} {$meeting.forms.GES.Nombrederadiateurs}{$billing.contract.forms.GES.Nombrederadiateurs}", `<strong>${this.modelVT.nbRadiateurs}</strong>`)
          this.kit.description = this.kit.description.replaceAll("{$contract.forms.GES.Nombrederadiateurs}{$meeting.forms.GES.Nombrederadiateurs}", `<strong>${this.modelVT.nbRadiateurs}</strong>`)
          this.kit.description = this.kit.description.replace("{$contract.request.surface_home}{$billing.request.surface_home} {$meeting.request.surface_home}", `<strong>${this.mapSuperficieToIntervale(this.modelVT.superficie)}</strong>`)
          this.kit.description = this.kit.description.replace("{$meeting.request.energy}{$contract.request.energy}{$billing.request.energy}", `<strong>${this.modelVT.typeChauffage}</strong>`)
          this.kits.push(this.kit)
        }

        console.log(this.kit)
      },
      mapSuperficieToIntervale(superficie) {

          if(superficie < 35) {
            return "< 35"  
          }

          if(superficie >= 35 && superficie < 60) {
            return "35 ≤ S < 60"
          }

          if(superficie >= 60 && superficie < 70) {
            return "60 ≤ S < 70"
          }

          if(superficie >= 70 && superficie < 90) {
            return "70 ≤ S < 90"
          }

          if(superficie >= 90 && superficie < 110) {
            return "90 ≤ S < 110"
          }

          if(superficie >= 110 && superficie < 130) {
            return "110 ≤ S ≤ 130"
          }

          if(this.superficie >= 130) {
            return ">130"
          }

          return ""
      },
      capitalizeFirstLetter(str) {
          if (!str) return str; // Handle empty string or null/undefined
          return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      },
      msgBox(message, titre, variant) {
        return this.$bvModal.msgBoxOk(message, {
              title: titre,
              okVariant: variant,
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
    },
    async update() {
      if(this.meeting) {
        this.updateMeeting()
      }
      else {
        this.updateContract()
      }
    },

    async getMeeting() {
      try {
        let result = await MeetingService.GetMeeting(this.meeting.id)
        if(result.status != 200 || result.data.errors) {
            this.msgBox(`Impossible de recuperer le RDV : ${JSON.stringify(result.data.errors)}`, 'Erreur', 'danger')
            return
        }  

        this.extra = result.data.data.forms.GES
        // "GES": {
        //         "Typedelogement": "MAISON",
        //         "RADIATEURELECTRIQUE": "",
        //         "CHAUDIEREINDIVIDUELLEAGAZ": "",
        //         "CHAUDIEREINDIVIDUELLEAELECTRIQUE": "",
        //         "PACAIREAU": "",
        //         "AUTRE": "aaaaaaaaaaa",
        //         "Nombrederadiateurs": "2",
        //         "smartphone": "NON",
        //         "PDL": "447022",
        //         "accumulationelect": "",
        //         "vannesgaz": "",
        //         "vanneselec": "",
        //         "vannespac": "",
        //         "societe": "",
        //         "siret": "",
        //         "2ans": "OUI",
        //         "typechauffage": "CHAUDIERE INDIVIDUELLE ELECTRIQUE (Uniquement vannes thermostatiques)",
        //         "Fonctionrepresentant": "",
        //         "mixteelectrique": 8,
        //         "mixtehydrolique": 8
        //     }

        console.log(this.extra)
        this.modelVT.setValueByRef("typecha","Individuel")
        //this.modelVT.setValueByRef("surface",result.data.data.)
        this.extra.AUTRE != "" || this.extra.AUTRE != null ? this.modelVT.setValueByRef('autre', this.extra.AUTRE ) : ""
        this.extra.Nombrederadiateurs != "" || this.extra.Nombrederadiateurs != null ? this.modelVT.setValueByRef('nbRad', this.extra.Nombrederadiateurs) : ""
        this.extra.PDL != "" || this.extra.PDL != null ? this.modelVT.setValueByRef('pdl', this.extra.PDL) : ""
        this.extra.mixteelectrique != "" || this.extra.mixteelectrique != null ? this.modelVT.setValueByRef('nbRadElec', this.extra.mixteelectrique ) : "0"
        this.extra.mixtehydrolique != "" || this.extra.mixtehydrolique != null ? this.modelVT.setValueByRef('nbRadHydro', this.extra.mixtehydrolique)  : "0"
        this.extra['2ans'] != "" || this.extra['2ans'] != null ? this.modelVT.setValueByRef('ageLog', this.capitalizeFirstLetter(this.extra['2ans']))  : ""
        this.extra.typechauffage != "" || this.extra.typechauffage != null ? this.modelVT.setValueByRef('typeChau', this.extra.typechauffage)  : "",
        this.extra.Typedelogement != "" || this.extra.Typedelogement != null ? this.modelVT.setValueByRef('typeLog', this.capitalizeFirstLetter(this.extra.Typedelogement))  : ""
        this.extra.smartphone != "" || this.extra.smartphone != null ? this.modelVT.setValueByRef('smart', this.capitalizeFirstLetter(this.extra.smartphone)) : ""

        // "Radiateur électrique",
        //         "Chaudière individuelle gaz ",
        //         "Chaudière individuelle électrique",
        //         "Mixte",
        //         "PAC Air/Eau",
        //         "Autre"  

        switch(this.extra.typechauffage) {
          case "CHAUDIERE INDIVIDUELLE A GAZ (Uniquement vannes thermostatiques)":
            this.modelVT.setValueByRef('typeChau', "Chaudière individuelle gaz ")
            this.modelVT.setValueByRef('van', "Thermostatiques")
            break;
          case "RADIATEUR ELECTRIQUE (hors chauffage à accumulation)":
            this.modelVT.setValueByRef('typeChau', "Radiateur électrique")
            this.modelVT.setValueByRef('accu', "Non")
            break;
          case "PACAIREAU (Uniquement vannes thermostatiques)":
            this.modelVT.setValueByRef('typeChau', "Chaudière individuelle gaz ")
            this.modelVT.setValueByRef('van', "Thermostatiques")
            break;
          case "CHAUDIERE INDIVIDUELLE ELECTRIQUE (Uniquement vannes thermostatiques)":
            this.modelVT.setValueByRef('typeChau', "Chaudière individuelle électrique")
            this.modelVT.setValueByRef('van', "Thermostatiques")
            break;
          case "MIXTE":
            this.modelVT.setValueByRef('typeChau', "Mixte")
            break;

        }
      } catch (error) {
        this.msgBox(`Impossible de recuperer le RDV : ${error.message}`, 'Erreur', 'danger')
      }
    },
    async updateMeeting() { 

      let meeting = {... this.meeting}
        meeting.more_2_years = this.modelVT.getValueByRef('ageLog') == 'Oui' ? 'YES' : 'NO'
        meeting.revenue = 1000
        meeting.energy_id= 1
        meeting.occupation_id = 1
        meeting.layer_type_id = 1
        meeting.surface_home = this.modelVT.getValueByRef('surface')
        meeting.pricing_id = 5
        meeting.number_of_people = 1
        meeting.number_of_parts = 1
        meeting.declarants= 1
        meeting.number_of_fiscal = 1
        meeting.surface_ite = 1
        meeting.parcel_surface = 0
        meeting.pack_quantity =1
        meeting.extra = {
          autre : this.modelVT.getValueByRef('autre'),	
          nb_rad: this.modelVT.getValueByRef('nbRad'), 
          pdl: this.modelVT.getValueByRef('pdl'),
          nb_rad_elec :this.modelVT.getValueByRef('nbRadElec'),
          nb_rad_hydro: this.modelVT.getValueByRef('nbRadHydro'), 
          more_2_years : this.modelVT.getValueByRef('ageLog') == 'Oui' ? 1 : 2,
          typechauffage : this.modelVT.getValueByRef('typeChau'),
          typedelogement : this.modelVT.getValueByRef('typelog') == "Maison" ? 0 : 1,	
          smartphone : this.modelVT.getValueByRef('smart') == 'Oui' ? 1 : 0
        }

      switch (this.modelVT.getValueByRef('typeChau')) {
        case "Radiateur électrique" :
          meeting.extra.typechauffage = 1
          meeting.energy_id = 1
          break;
        case  "Chaudière individuelle gaz " :
          meeting.extra.typechauffage = 4
          meeting.energy_id = 3
          break;
        case  "Chaudière individuelle électrique" :
          meeting.extra.typechauffage = 3
          meeting.energy_id = 1
          break;
        case  "Mixte" :
          meeting.extra.typechauffage = 5
          break;
        case  "PAC Air/Eau" :
          meeting.extra.typechauffage = 2
          break;
        case  "Autre"  :
          meeting.extra.typechauffage = 6
          break;
        default:
          break;
      }

      try {
        let result = await MeetingService.UpdateMeeting(meeting)
        if(result.status != 200 || result.data.errors) {
            this.$bvModal.msgBoxOk(`Impossible d'enregistrer le RDV : ${JSON.stringify(result.data.errors)}`, {
              title: 'Erreur',
              okVariant: 'danger',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
            return
          }  

          //this.$store.dispatch('setMeeting', null)
      } catch (error) {
        this.$bvModal.msgBoxOk(`Impossible d'enregistrer le RDV : ${error.message}`, {
            title: 'Erreur',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
      }
      

      this.goToOrder()
    },
    async updateContract() { 

      let contract = {... this.contract}
        contract.more_2_years = this.modelVT.getValueByRef('ageLog') == 'Oui' ? 'YES' : 'NO'
        contract.revenue = 1000
        contract.energy_id= 1
        contract.occupation_id = 1
        contract.layer_type_id = 1
        contract.surface_home = this.modelVT.getValueByRef('surface') ? this.modelVT.getValueByRef('surface') : 1
        contract.pricing_id = 5
        contract.number_of_people = 1
        contract.number_of_parts = 1
        contract.declarants= 1
        contract.number_of_fiscal = 1
        contract.surface_ite = 1
        contract.parcel_surface = 0
        contract.pack_quantity =1
        

      try {
        let result = await ContractService.UpdateContract(contract)
        if(result.status != 200 || result.data.errors) {
            this.$bvModal.msgBoxOk(`Impossible d'enregistrer le contrat : ${JSON.stringify(result.data.errors)}`, {
              title: 'Erreur',
              okVariant: 'danger',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
            return
          }  

          //this.$store.dispatch('setContract', null)

      } catch (error) {
        this.$bvModal.msgBoxOk(`Impossible d'enregistrer le contrat : ${error.message}`, {
            title: 'Erreur',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
      }


      this.goToOrder()
      },
    // onDossierConfirmed(observations) {
    //   console.log(observations)
    //   this.observations = observations
    // },
    async newFolder() {
      console.log('Fidealis')

      try {
        this.loading= true;
        let result = await FidealisService.NewFolder(this.$route.params.contract_id)
          this.loading = false;
          if(result.status == 200) {
            this.$bvModal.msgBoxOk('Nouveau dossier crée avec succès', {
              title: 'Confirmation',
              okVariant: 'success',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
          }
      } catch (error) {
        this.loading = false;
          this.$bvModal.msgBoxOk(`Impossible de créer le dossier : ${error.message}`, {
            title: 'Erreur',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
      }
    },
    updateTabindex(index) {
      this.tabIndex = index
    },
    goToOrder() {
      this.$refs.observer_elegible.validate().then(success => {

          if(success) {
            this.selectedTab='commande';
            this.tabIndex=2
          }
        })
    },
    etat(id) {
      var str = ''
      switch (id) {
        case '1':
          str = 'success'
          break
        case '2':
          str = 'danger'
          break
        case '3':
          str = 'light'
          break
        case '0':
          str = 'warning'
          break
        default:
          str = ''
          break;
      }
      return str
    },
    linkClass(tab) {
      if (this.selectedTab === tab) {
        return ['bg-primary', 'text-light']
      } else {
        return ['bg-light', 'text-primary']
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onSubmit (evt) {
      evt.preventDefault()
      alert(JSON.stringify(this.form))
    },
    resetVT(idDossier) {
      console.log("resetVT")
      this.blocs.forEach(bloc => {
        bloc.questions.forEach(question => {
          Array.isArray(question.value) ? question.value = [] : question.value = null
          if(question.sub_questions)
          {
            question.sub_questions.forEach(subQuestion => {
              Array.isArray(subQuestion.value) ? subQuestion.value = [] : subQuestion.value = null
            })
          }
        })        
      });

      this.$store.dispatch("viderDocuments")
      this.idDossier = idDossier
      //this.$router.push('/clients');
    },
    onReset (evt) {
      evt.preventDefault()
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    onClicked(val) {
      console.log(val)
      this.selectedTab = val
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

legend {
  font-size: 16px;
}

p {
  padding: 10px;
}
</style>
