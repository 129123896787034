import Api from '@/services/Api'
import authHeader from './Auth-header';

async function ListMeeting(beginDate, endDate, signed){

	let form = new FormData();

	form.append('filter[range][in_at][from]', new Date( beginDate).toLocaleDateString('fr-FR'));
	form.append('filter[range][in_at][to]', new Date( endDate).toLocaleDateString('fr-FR')); 

	switch (signed) {
		case true:
			form.append('filter[equal][quotation_is_signed]',"YES"); 
			break;
		case false:
			form.append('filter[equal][quotation_is_signed]',"NO"); 
			break;
		default:
			break;
	}
	
	const r = Api.post(`/admin/api/v2/meetings/admin/ListMeeting?token=${authHeader()}`, form)
	return r
}

async function ListEnergy(){

	const r = Api.get(`/admin/api/v2/applications/iso0/admin/ListEnergy?token=${authHeader()}`)

	return r
}

async function ListStateMeeting(){

	const r = Api.get(`/admin/api/v2/meetings/admin/ListStateMeeting?token=${authHeader()}`)

	return r
}

async function GetMeeting(id){

	const r = Api.get(`/admin/api/v2/meetings/admin/GetMeeting?token=${authHeader()}&id=${id}`) 
	return r
}

async function NewMeeting(data){

	let form = new FormData();

	const token = authHeader()

	form.append('customer[gender]', data.customer.gender);
	form.append('customer[firstname]', data.customer.firstname); 
	form.append('customer[lastname]', data.customer.lastname); 
	form.append('customer[phone]', data.customer.phone); 
	form.append('customer[mobile]', data.customer.mobile); 
	form.append('customer[email]', data.customer.email); 
	form.append('meeting[in_at][date]', new Date( data.in_at_date).toLocaleDateString('fr-FR')); 
	form.append('meeting[in_at][hour]', data.in_at_hour); 
	form.append('meeting[in_at][minute]', data.in_at_minute); 
	form.append('meeting[state_id]', data.state_id); 
	form.append('address[address1]', data.address.address1); 
	form.append('address[postcode]', data.address.postcode); 
	form.append('address[city]', data.address.city); 
	form.append("meeting[polluter_id]", data.polluter_id);
	form.append("meeting[opc_range_id]", 1);
	form.append("meeting[created_at]", new Date().toLocaleDateString('fr-FR'))
	form.append("meeting[opc_at]", new Date().toLocaleDateString('fr-FR'));
	form.append("iso[pricing_id]", data.pricing_id);
	form.append("iso[energy_id]", data.energy_id);
	form.append("iso[occupation_id]", data.occupation_id);
	form.append("iso[more_2_years]", data.more_2_years);
	form.append("iso[number_of_people]", data.number_of_people);
	form.append("iso[revenue]", data.revenue);
	form.append("iso[number_of_parts]", data.number_of_parts);
	form.append("iso[declarants]", data.declarants);
	form.append("iso[number_of_fiscal]", data.number_of_fiscal);
	form.append("iso[surface_ite]", data.surface_ite);
	form.append("iso[parcel_surface]", data.parcel_surface);
	form.append("iso[layer_type_id]", data.layer_type_id);
	form.append("verif", "");
	form.append("iso[pack_quantity]", data.pack_quantity);

	return Api.post(`/admin/api/v2/meetings/admin/NewMeeting?token=${token}`, form)
}

async function UpdateMeeting(data){

	let form = new FormData();

	const token = authHeader()

	form.append('id', data.id);
	form.append('customer[gender]', data.customer.gender);
	form.append('customer[firstname]', data.customer.firstname); 
	form.append('customer[lastname]', data.customer.lastname); 
	form.append('customer[phone]', data.customer.phone); 
	form.append('customer[mobile]', data.customer.mobile); 
	form.append('customer[email]', data.customer.email); 
	if(data.in_at_date) {
		form.append('meeting[in_at][date]', new Date( data.in_at_date).toLocaleDateString('fr-FR')); 
		form.append('meeting[in_at][hour]', data.in_at_hour); 
		form.append('meeting[in_at][minute]', data.in_at_minute); 
	}else {
		const date = new Date( data.in_at);  // 2024-05-03 12:00:00
		form.append('meeting[in_at][date]', date.toLocaleDateString('fr-FR')); 
		form.append('meeting[in_at][hour]', date.getHours()); 
		form.append('meeting[in_at][minute]', date.getMinutes());
	}
	form.append('meeting[state_id]', data.state_id); 
	form.append('address[address1]', data.address.address1); 
	form.append('address[postcode]', data.address.postcode); 
	form.append('address[city]', data.address.city); 
	form.append("meeting[polluter_id]", data.polluter_id);
	form.append("meeting[opc_range_id]", 1);
	form.append("meeting[created_at]", new Date().toLocaleDateString('fr-FR'))
	form.append("meeting[opc_at]", new Date( data.opc_at).toLocaleDateString('fr-FR'));
	form.append("iso[pricing_id]", data.pricing_id);
	form.append("iso[energy_id]", data.energy_id);
	form.append("iso[occupation_id]", data.occupation_id);
	form.append("iso[more_2_years]", data.more_2_years);
	form.append("iso[number_of_people]", data.number_of_people);
	form.append("iso[revenue]", data.revenue);
	form.append("iso[number_of_parts]", data.number_of_parts);
	form.append("iso[declarants]", data.declarants);
	form.append("iso[number_of_fiscal]", data.number_of_fiscal);
	form.append("iso[surface_home]", data.surface_home);
	form.append("iso[surface_ite]", data.surface_ite);
	form.append("iso[parcel_surface]", data.parcel_surface);
	form.append("iso[layer_type_id]", data.layer_type_id);
	form.append("verif", data.verif);
	form.append("iso[pack_quantity]", data.pack_quantity);
	if(data.extra) {
		form.append("extra[GES][AUTRE]",data.extra.autre)	
		form.append("extra[GES][Nombrederadiateurs]",data.extra.nb_rad)
		form.append("extra[GES][PDL]",data.extra.pdl)		
		form.append("extra[GES][mixteelectrique]",data.extra.nb_rad_elec)		
		form.append("extra[GES][mixtehydrolique]",data.extra.nb_rad_hydro)		
		form.append("extra[GES][2ans]",data.extra.more_2_years)		
		form.append("extra[GES][typechauffage]",data.extra.typechauffage)	
		form.append("extra[GES][Typedelogement]",data.extra.typedelogement)	
		form.append("extra[GES][smartphone]",data.extra.smartphone)	
	}
	

	return Api.post(`/admin/api/v2/meetings/admin/UpdateMeeting?token=${token}`, form)
}

export default {
	ListMeeting, NewMeeting, UpdateMeeting, ListEnergy, ListStateMeeting, GetMeeting
}