export default class ModelVT {
  constructor() {
    this.validation_errors = []
    this.blocs = null
    this.rules = null
  }

  get individuel () {
    // return this.blocs[1].questions[0].value
    return this.getValueByRef('typecha')
  }

  get typeChauffage () {
    // return this.blocs[1].questions[0].sub_questions[2].value
    return this.getValueByRef('typeChau')
  }


  set typeChauffage (value) {
    // return this.blocs[1].questions[0].sub_questions[2].value = value
    return this.setValueByRef('typeChau', value)
  }

  get chauffageAccumulation () {
    // return this.blocs[1].questions[0].sub_questions[2].sub_questions[0].value
    return this.getValueByRef('accu')
  }

  set chauffageAccumulation (value) {
    //this.blocs[1].questions[0].sub_questions[2].sub_questions[0].value = value
    this.setValueByRef('accu', value)
  }

  get vannes () {
    return this.getValueByRef('van')
    //return this.blocs[1].questions[0].sub_questions[2].sub_questions[1].value
  }

  set vannes (value) {
    this.setValueByRef('van', value)
    //this.blocs[1].questions[0].sub_questions[2].sub_questions[1].value = value
  }

  get nbRadiateurs () {
    return this.getValueByRef('nbRad')
    //return this.blocs[1].questions[0].sub_questions[3].value
  }

  get telecommande () {
    return this.getValueByRef('smart') == "Non"
    //return this.blocs[1].questions[0].sub_questions[5].value == "Non" // Pas de smartphone
  }

  get isEligibleLuminaire(){
    return this.getValueByRef('lumsup2ans') == "Oui" && this.getValueByRef('typeEclairage').includes("Eclairage terrain de sport ou mise en valeur de site (non éligible)")
    //return this.blocs[0].questions[1].value == "Oui" &&
    //  !this.blocs[0].questions[2].value.includes("Eclairage terrain de sport ou mise en valeur de site (non éligible)")
  }

  get isEligibleThermostatique(){
    return this.individuel == "Individuel" && ( this.chauffageAccumulation == "Non" || this.vannes == "Thermostatiques" || this.chauffageAccumulation)
  }

  get superficie() {
    return this.getValueByRef('surface')
    //return this.blocs[1].questions[0].sub_questions[4].value
  }

  get facteurCorrectif() {

    if(this.superficie < 35) {
      return 0.5
    }

    if(this.superficie >= 35 && this.superficie < 60) {
      return 0.6
    }

    if(this.superficie >= 60 && this.superficie < 70) {
      return 0.7
    }

    if(this.superficie >= 70 && this.superficie < 90) {
      return 0.8
    }

    if(this.superficie >= 90 && this.superficie < 110) {
      return 1
    }

    if(this.superficie >= 110 && this.superficie < 130) {
      return 1.1
    }

    if(this.superficie >= 130) {
      return 1.2
    }

    return 0
  }

  get tva() {
    return this.getValueByRef('ageLog') == "Oui" ? 5.5 : 10
  }

  getNextId() {
    let maxId = -1;

    function exploreObject(o) {
      if (o && typeof o === 'object') {
        if ('id' in o && typeof o.id === 'number') {
          maxId = Math.max(maxId, o.id);
        }

        for (const key in o) {
          exploreObject(o[key]);
        }
      }
    }

    exploreObject(this.blocs);

    return maxId + 1;
  }

  getById(id) {
    let maxId = -1;

    function exploreObject(o) {
      if (o && typeof o === 'object') {
        if ('id' in o && typeof o.id === 'number') {
          maxId = Math.max(maxId, o.id);
        }

        for (const key in o) {
          exploreObject(o[key]);
        }
      }
    }

    exploreObject(id);

    return maxId + 1;
  }

  deleteById(arr, targetId) {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === targetId) {
            arr.splice(i, 1);
            return true; // Object found and deleted
        }

        if (arr[i].questions && Array.isArray(arr[i].questions)) {
            // Recursively search in sub-questions
            if (this.deleteById(arr[i].questions, targetId)) {
                return true; // Object found and deleted
            }
        }

		if (arr[i].sub_questions && Array.isArray(arr[i].sub_questions)) {
            // Recursively search in sub-questions
            if (this.deleteById(arr[i].sub_questions, targetId)) {
                return true; // Object found and deleted
            }
        }
    }

    return false; // Object not found
  }

  getQuestionById(id, currentNode = this.blocs) {

    if (!currentNode) {
      return null;
    }
    
    for (const node of currentNode) {

      if (node.id == id) {
        return node;
      }

      if (node.questions && node.questions.length > 0) {
        for (const question of node.questions) {

          if (question.id == id) {
              return question;
          }

          if (question.sub_questions && question.sub_questions.length > 0) {
            const result = this.getValueByRef(id, question.sub_questions);

            if (result) {
              return result;
            }
          }
        }
      }
    }

    return null;
  }

  getValueByRef(reference, currentNode = this.blocs) {
  
    if (!currentNode) {
      return "";
    }
    
    for (const node of currentNode) {

      if (node.comment === reference) {
        return node.value;
      }

      if (node.questions && node.questions.length > 0) {
        for (const question of node.questions) {

          if (question.comment === reference) {
              return question.value;
          }

          if (question.sub_questions && question.sub_questions.length > 0) {
            const result = this.getValueByRef(reference, question.sub_questions);

            if (result) {
              return result;
            }
          }
        }
      }

      if (node.sub_questions && node.sub_questions.length > 0) {
        const result = this.getValueByRef(reference, node.sub_questions);

        if (result) {
          return result;
        }
      }
    }

    return "";
  }


  getTitleByRef(reference, currentNode = this.blocs) {
  
    if (!currentNode) {
      return null;
    }
    
    for (const node of currentNode) {

      if (node.comment === reference) {
        return node.title;
      }

      if (node.questions && node.questions.length > 0) {
        for (const question of node.questions) {

          if (question.comment === reference) {
              return question.title;
          }

          if (question.sub_questions && question.sub_questions.length > 0) {
            const result = this.getTitleByRef(reference, question.sub_questions);

            if (result) {
              return result;
            }
          }
        }
      }

      if (node.sub_questions && node.sub_questions.length > 0) {
        const result = this.getTitleByRef(reference, node.sub_questions);

        if (result) {
          return result;
        }
      }
    }

    return null;
  }

  setValueByRef(reference, value, currentNode = this.blocs) {
  
    if (!currentNode) {
      return;
    }
    
    for (const node of currentNode) {

      if (node.comment === reference) {
        node.value = value;
      }

      if (node.questions && node.questions.length > 0) {
        for (const question of node.questions) {

          if (question.comment === reference) {
              question.value = value;
          }

          if (question.sub_questions && question.sub_questions.length > 0) {
            this.setValueByRef(reference, value, question.sub_questions);
            
          }
        }
      }

      if (node.sub_questions && node.sub_questions.length > 0) {
        this.setValueByRef(reference,value, node.sub_questions);
      }
    }

    return;
  }


  reset(currentNode = this.blocs) {
    
    for (const node of currentNode) {

        node.value = null;

      if (node.questions && node.questions.length > 0) {
        for (const question of node.questions) {

          question.value = null;

          if (question.sub_questions && question.sub_questions.length > 0) {
            this.reset(question.sub_questions);
            
          }
        }
      }

      if (node.sub_questions && node.sub_questions.length > 0) {
        this.reset(node.sub_questions);
      }
    }
  }

  isValid() {
    this.validation_errors = []
    this.blocs.forEach(bloc => {
      bloc.questions.forEach(question => {
        if(question.validation_error != null && question.validation_error != "" && question.value == null) {
          this.validation_errors.push(question.title)
        }
        if(question.sub_questions) {
          question.sub_questions.forEach(sub => {
            if(sub.validation_error != null && question.validation_error != "" && sub.selected_parent_value == question.value && (sub.value == null || sub.value.length == 0)) {
              this.validation_errors.push(question.title + ": " + sub.title)
            }
          })
        }
      })
    });

  }

  parse(vt) {
    if (vt === undefined || vt == '')
      return this.blocs;

    vt.forEach(bloc => {
      var currentBloc = this.blocs.filter(x => { return x.id == bloc.id })
      if (currentBloc.length > 0) {
        bloc.questions.forEach(question => {
          var currentQuestion = currentBloc[0].questions.filter(q => { return q.id == question.id })
          if (currentQuestion.length > 0) {
            currentQuestion[0].value = question.value
            if (question.sub_questions !== undefined) {
              question.sub_questions.forEach(sub => {
                var currentSub = currentQuestion[0].sub_questions.filter(s => { return s.id == sub.id })
                if (currentSub.length > 0) {
                  currentSub[0].value = sub.value
                }
              })
            }
          }
        })
      }
    });

    return this.blocs;
  }

  transformCondition(conditions) {

    // Define a function to transform each individual condition
    function transformSingleCondition(condition) {     
      // Construct the transformed condition
      return `this.getValueByRef('${condition.key}') ${condition.operator} '${condition.value}'`;
    }
  
    // Map over each condition, transform it, and join them with '&&' 
    return conditions.map(transformSingleCondition).join(' && ');
  }

  // Function to check conditions for each product
  findProductId() {
    for (const rule of this.rules) {

      // Evaluate the condition dynamically
      if (eval(this.transformCondition(rule.conditions))) {
        return rule.productId;
      }
    }
    // If no conditions are met, return null or handle appropriately
    return null;
  }

}