<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <b-card title="Devis" class="mb-3">
      <div  v-if="quotation">  
            <div class="d-flex w-100 justify-content-between flex-row">
              <div class="d-flex  flex-column">
                <p class="mb-1"><b-icon icon=""></b-icon>Ref :{{quotation.reference}}  </p>
                <p class="mb-1"><b-icon icon="calendar"></b-icon> Le : <span>{{quotation.dated_at}}</span></p>
                <p class="mb-1" v-if="quotation && quotation.is_signed == 'Oui'"><b-icon icon="vector-pen"></b-icon> Signe le <span>{{quotation.signed_at}}</span></p>
              </div>
              <div class="d-flex justify-content-start align-items-center flex-column">
                <p class="mb-1"><b-icon icon=""></b-icon>Prime CEE :{{quotation.prime_cee}}  €</p>
                <p class="mb-1"><b-icon icon=""></b-icon>Total HT :{{quotation.totalHT}}  €</p>
                <p class="mb-1"><b-icon icon=""></b-icon>Total TVA :{{quotation.totalTVA}}  €</p>
                <p class="mb-1"><b-icon icon=""></b-icon><span class="font-weight-bold">Total TTC : {{quotation.totalTTC}} €</span>  </p>
                
              </div>
            </div>
            <button type="button" @click="download('quotation', quotation.id)"  :disabled="loading" class="btn btn-outline-primary mr-2 mb-2"><b-spinner v-if="loading" small></b-spinner><b-icon icon="download"></b-icon> Télécharger</button>
            <button type="button" v-if="quotation && quotation.is_signed =='Non'" @click="signDocument('quotation', quotation.id)" :disabled="loading" class="btn btn-outline-primary mr-2 mb-2"><b-spinner v-if="loading" small></b-spinner><b-icon icon="vector-pen"></b-icon> Envoyer signature électronique</button>
            <button type="button" v-if="billings.length == 0" @click="CreateBilling(contractId, quotation.id)" :disabled="loading" class="btn btn-outline-primary mr-2 mb-2"><b-spinner v-if="loading" small></b-spinner><b-icon icon="clipboard"></b-icon> créer une facture</button>
            <button type="button" v-if="quotation && quotation.is_signed == 'Oui'" @click="download('proof', quotation.id)"  :disabled="loading" class="btn btn-outline-primary mr-2 mb-2"><b-spinner v-if="loading" small></b-spinner><b-icon icon="download"></b-icon> Preuve</button>
            <button type="button" v-if="quotation && quotation.is_signed == 'Oui'" @click="download('signed', contractId, null, quotation.id)"  :disabled="loading" class="btn btn-outline-primary mr-2 mb-2"><b-spinner v-if="loading" small></b-spinner><b-icon icon="download"></b-icon> Document signé</button>
          </div>
          <div v-else>
              Pas de devis
          </div>
     </b-card>
  </div>
</template>

<script>
import DevisService from '../services/DevisService'
import YousignService from '../services/YousignService'
import BillingService from '../services/BillingService'
export default {
  name: 'MQuotationCard',
  components: {
   
  },
  props: ['quotation', 'billings','contractId'],
  data: () => ({
    loading : false,
    message : ""
  }),
  computed: {

  },
  methods: {
    async signDocument(type,id) {

    try {
      this.message = ""
      this.loading = true
      let result = null


      switch (type) {
        case "quotation":
          result = await DevisService.EnvoiSignatureElectronique(id) // quotationId
          break;
        default:
          break;
      }
      this.loading = false

      if(result.status != 200 || result.data.errors) {
        this.$bvModal.msgBoxOk(`Impossible d'envoyer le document en signature électronique' : ${JSON.stringify(result.data.errors)}`, {
          title: 'Erreur',
          okVariant: 'danger',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
        return
      }   

      this.$bvModal.msgBoxOk(`Document envoye en signature électronique avec succes`, {
          title: 'Confirmation',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })

      } catch (error) {
        this.loading = false
        this.$bvModal.msgBoxOk(`Impossible d'envoyer le document en signature électronique : ${error.message}`, {
            title: 'Erreur',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
      }
    },
    async download(type, id, id1, id2) {

      try {
        this.message = ""
        this.loading = true
        let result = null
        
        switch (type) {
          case "quotation":
            result = await DevisService.ExportPdfDevis(id) // quotationId
            break;
          case "signed":
            result = await YousignService.ExportPdfDevisSigne(id, id1, id2) // contractId, meetingId, quotationId
            break;
          case "proof":
            result = await YousignService.ExportProofPdfFile(id, null) // fileId
              break;
          default:
            break;
        }

        this.loading = false


        if(result.status != 200 || result.data.errors) {
          this.$bvModal.msgBoxOk(`Impossible de télécharger le document : ${JSON.stringify(result.data.errors)}`, {
            title: 'Erreur',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
          return
        }   

        window.open(URL.createObjectURL(new Blob([result.data], { type: 'application/pdf' })))
        //this.$bvModal.show('modal-confirm')
          
        } catch (error) {
          this.loading = false
          this.$bvModal.msgBoxOk(`Impossible de visualiser le document : ${error.message}`, {
              title: 'Erreur',
              okVariant: 'danger',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
      }
    }, 
    async CreateBilling(contractId, quotationId){
      try {
        this.loading= true;
        let result = await BillingService.CreateBilling(contractId, quotationId)
          this.loading = false;
          if(result.status != 200 || result.data.error){

            throw new Error(result.data.error)
          }
          await this.GetBilling()
      } catch (error) {
        this.loading = false;
          this.$bvModal.msgBoxOk(`Impossible de créer la facture : ${error.message}`, {
            title: 'Erreur',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
      }
  
    },
  }
}
</script>

<style>

</style>