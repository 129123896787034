import Api from '@/services/Api'
import authHeader from './Auth-header';

async function ExportPdfDevis(quotation){

	const token = authHeader()

	return Api.get(`/admin/api/v2/applications/iso/admin/ExportPdfQuotation?quotation=${quotation}&token=${token}` , { responseType: 'blob' })
}


async function ListMeetingQuotation(meeting_id){

	let form = new FormData();

	form.append("filter[equal][meeting_id]", meeting_id);
	form.append("filter[equal][is_last]", "YES");

	return ListQuotation(form)
}

async function ListContractQuotation(contract_id){

	let form = new FormData();

	form.append("filter[equal][contract_id]", contract_id);
	form.append("filter[equal][is_last]", "YES");

	return ListQuotation(form)
}

async function ListQuotation(form){

	const token = authHeader()
	return Api.post(`/admin/api/v2/applications/iso/admin/ListQuotation?token=${token}` , form)
}


async function NewDevisMeeting(data){

	let form = new FormData();

	const token = authHeader()

	form.append("meeting_id", data.meeting_id);
	form.append("polluter_id", data.polluter_id);
	form.append("master_item_id", data.master_item_id);
	form.append("items[0][item_id]", data.item_id);
	form.append("items[0][quantity]", 1);
	form.append("items[0][price]", data.price);
	form.append("dated_at", new Date().toLocaleDateString('fr-FR'));
	

	return Api.post(`/admin/api/v2/applications/iso3/admin/NewQuotationMeeting?token=${token}`, form)
}

async function UpdateDevisMeeting(data){

	let form = new FormData();

	const token = authHeader()

	form.append("id", data.id);
	form.append("meeting_id", data.meeting_id);
	form.append("polluter_id", data.polluter_id);
	form.append("master_item_id", data.master_item_id);
	form.append("items[0][item_id]", data.item_id);
	form.append("items[0][quantity]", 1);
	form.append("items[0][price]", data.price);
	form.append("dated_at", new Date().toLocaleDateString('fr-FR'));
	

	return Api.post(`/admin/api/v2/applications/iso3/admin/UpdateQuotationMeeting?token=${token}`, form)
}

async function NewDevisContract(data){

	let form = new FormData();

	const token = authHeader()

	form.append("contract_id", data.contract_id);
	form.append("polluter_id", data.polluter_id);
	form.append("master_item_id", data.master_item_id);
	form.append("items[0][item_id]", data.item_id);
	form.append("items[0][quantity]", 1);
	form.append("items[0][price]", data.price);
	form.append("dated_at", new Date().toLocaleDateString('fr-FR'));
	

	return Api.post(`/admin/api/v2/applications/iso3/admin/NewQuotationContract?token=${token}`, form)
}



export default {
	NewDevisMeeting, NewDevisContract, ExportPdfDevis,  ListMeetingQuotation, ListContractQuotation , UpdateDevisMeeting
}