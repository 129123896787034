<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div v-if="question" class="d-flex flex-column">
      <div class="d-flex justify-content-between align-items-center">  
        <div>
          <b-icon :icon="iconType(question.question_type)"></b-icon> 
          {{question.title}} 
          <h5 v-if="question.question_type =='section'">{{question.title}}</h5>
        </div>
        <div>
          <b-icon :icon="handleHover('plus-circle', 'plus-circle-fill', isOverPlus)" @click="openAdd(question, question)" @mouseover="isOverPlus = true" @mouseout="isOverPlus = false" class="mr-1" variant="success" :style="isOverPlus ? 'cursor:pointer' : ''"> </b-icon>
          <b-icon :icon="handleHover('pencil', 'pencil-fill', isOverPencil)" @click="openEdit(question, parent)" @mouseover="isOverPencil = true" @mouseout="isOverPencil = false" class="mr-1" variant="info"  :style="isOverPencil ? 'cursor:pointer' : ''"> </b-icon>
          <b-icon :icon="handleHover('trash', 'trash-fill', isOverTrash)" @click="openDelete(question.id)" @mouseover="isOverTrash = true" @mouseout="isOverTrash = false" class="mr-1" variant="danger"  :style="isOverTrash ? 'cursor:pointer' : ''"> </b-icon>
        </div>
      </div>
 
      <div v-if="question.sub_questions && question.sub_questions.length > 0">
        <div v-for="subquestion in question.sub_questions" :key="subquestion.id">
          <b-row>
            <b-col cols="1"><b-icon icon="chevron-right"></b-icon></b-col>
            <b-col cols="11">
              <QuestionItemEdit  :question="subquestion" :blocId="subquestion.id" :parent="question"></QuestionItemEdit>
            </b-col>
          </b-row>
        </div>         
      </div>
        
      <ModalQuestionEdit :id="`modal-${question.comment}`" :mode="mode" @updateModel="updateModel"></ModalQuestionEdit>
    </div>
  </template>
  
  <script>
 
  import IcallService from '../services/IcallService'
import ModalQuestionEdit from './ModalQuestionEdit.vue'

  export default {
    name: 'QuestionItemEdit',
    components: {
     ModalQuestionEdit
    },
    props: {
      question : {
        type: Object,
        default() {
          return {  }
        }
      },
      parent : {
        type: Object,
        default() {
          return {  }
        }
      },
      blocId : Number
    },
    data: () => ({
      isOverGear : false,
      isOverPencil : false,
      isOverTrash : false,
      isOverPlus : false,
      currentParent : {},
      currentQuestion : {},
      mode : 'Edit'
    }),
    computed : {
      modelVT() {
        return this.$store.getters.modelVT
      },
    },
    methods: {
      handleHover(out, over, isHover) {
       return isHover ? over : out
      },
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
      },
      getImgUrl(src) {
        var images = require.context('../assets/', false)
        return images('./' + src)
      },
      iconType(questionType) {
        switch(questionType) {
          case 'radio':
            return "ui-radios"
          case 'select':
            return "list"
          case 'inputText':
            return "input-cursor-text"
          case 'checklist':
            return "list-check"
          case 'date':
            return "calendar2-date"
          case 'time':
            return "stopwatch"
          case 'textarea':
            return "textarea-resize"
        }
      },
      openEdit(question, parent) {
        console.log("OpenEdit")
        //
        this.mode="Edit"
        this.$store.dispatch('setQuestion', question);
        this.$store.dispatch('setParent', parent);
        this.$bvModal.show(`modal-${question.comment}`)
      },
      openAdd(question, parent) {

        console.log("OpenAdd")
        //
        this.mode="Add"
        const new_question = {
            "id": 0,
            "title": "",
            "comment": "",
            "question_type": "radio",
            "value_type": "radio",
            "default_value": null,
            "list_values": [],
            "value": null,
            "validation_error":null,
            "selected_parent_value": null,
            "sub_questions": null
          }
        
        this.$store.dispatch('setQuestion', new_question);
        this.$store.dispatch('setParent', parent);
        this.$bvModal.show(`modal-${question.comment}`)
        
      },
      openDelete(id){
        this.$bvModal.msgBoxConfirm('Voulez vous supprimer cette question ?', {
          title: 'Confirmation',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Oui',
          cancelTitle: 'Non',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if(value) {
            console.log(id)
            this.modelVT.deleteById(this.modelVT.blocs, id)
          }
        })
        .catch(err => {
          // An error occurred
          console.log(err)
        })
      },
      async updateModel(model) {
        console.log("updateModel",model)
        try {
          await IcallService.updateModelVT(model)
          this.$bvModal.msgBoxOk(`Model sauvegarde avec succes`, {
            title: 'Confirmation',
            okVariant: 'success',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
          
        } catch (error) {
          this.$bvModal.msgBoxOk(`Impossible de sauvegarder le model : ${error.message}`, {
            title: 'Erreur',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
        }
        this.$bvModal.hide(`modal-${this.currentQuestion.comment}`)
      }
    }
  }
  </script>
  
  <style>
  .sub_question {
    border: 0px black solid;
    margin-left: 0rem;
  }
  
  .invalid-feedback{
    font-size: 125% !important;;
    font-weight: bold;
  }
  
  .bordered {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  </style>