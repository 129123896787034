<template>
  <b-container class="pt-2" fluid="sm">
    <div class="d-flex flex-row mt-4  justify-content-start">
      <go-back/> <h3>Contrat</h3>
    </div>

    <ModalViewPDF id="modal-confirm" title="Visualisation du devis" :src="pdfStream" />

    
    <b-row>
      <b-col>
        <b-card title="" class="mb-3">    
            {{ customer.firstname }} {{ customer.lastname }}
        </b-card>
      </b-col>
    </b-row>

    <!-- <b-row>
      <b-col md="12">
        <b-card title="Devis" class="mb-3">                  
          <div  v-if="quotations.length > 0">
            <div class="d-flex w-100 justify-content-between flex-row">
              <div class="d-flex  flex-column">
                <p class="mb-1"><b-icon icon=""></b-icon>Ref :{{quotation.reference}}  </p>
                <p class="mb-1"><b-icon icon="calendar"></b-icon> Le : <span>{{quotation.dated_at}}</span></p>
                <p class="mb-1" v-if="quotation && quotation.is_signed == 'Oui'"><b-icon icon="vector-pen"></b-icon> Signe le <span>{{quotation.signed_at}}</span></p>
              </div>
              <div class="d-flex justify-content-start align-items-center flex-column">
                <p class="mb-1"><b-icon icon=""></b-icon>Prime CEE :{{quotation.prime_cee}}  €</p>
                <p class="mb-1"><b-icon icon=""></b-icon>Total HT :{{quotation.totalHT}}  €</p>
                <p class="mb-1"><b-icon icon=""></b-icon>Total TVA :{{quotation.totalTVA}}  €</p>
                <p class="mb-1"><b-icon icon=""></b-icon><span class="font-weight-bold">Total TTC : {{quotation.totalTTC}} €</span>  </p>
                
              </div>
            </div>
            <button type="button" @click="download('quotation', quotation.id)"  :disabled="loading" class="btn btn-outline-primary mr-2 mb-2"><b-spinner v-if="loading" small></b-spinner><b-icon icon="download"></b-icon> Télécharger</button>
            <button type="button" v-if="quotation && quotation.is_signed =='Non'" @click="signDocument('quotation', quotation.id)" :disabled="loading" class="btn btn-outline-primary mr-2 mb-2"><b-spinner v-if="loading" small></b-spinner><b-icon icon="vector-pen"></b-icon> Envoyer signature électronique</button>
            <button type="button" v-if="billings.length == 0" @click="CreateBilling(contractId, quotation.id)" :disabled="loading" class="btn btn-outline-primary mr-2 mb-2"><b-spinner v-if="loading" small></b-spinner><b-icon icon="clipboard"></b-icon> créer une facture</button>
            <button type="button" @click="download('proof', quotation.id)"  :disabled="loading" class="btn btn-outline-primary mr-2 mb-2"><b-spinner v-if="loading" small></b-spinner><b-icon icon="download"></b-icon> Preuve</button>
            <button type="button" @click="download('signed', contractId, quotation.id)"  :disabled="loading" class="btn btn-outline-primary mr-2 mb-2"><b-spinner v-if="loading" small></b-spinner><b-icon icon="download"></b-icon> Document signé</button>
          </div>
          <div v-else>
              Pas de devis
          </div>

        </b-card>
      </b-col>
    </b-row> -->
    <b-row>
      <b-col md="12">
        <quotation-card :quotation="quotation" :billings="billings" :contractId="contractId"></quotation-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card  title="Factures" class="mb-3" >
          <div  v-if="billings.length > 0">
          <div class="d-flex w-100 justify-content-between flex-row">
              <div class="d-flex  flex-column">
                <p class="mb-1"><b-icon icon=""></b-icon>Ref : {{billing.reference}}  </p>
                <p class="mb-1"><b-icon icon="calendar"></b-icon> Le : <span>{{billing.dated_at}}</span></p>
              </div>
              <div class="d-flex justify-content-start align-items-center flex-column">
                <p class="mb-1"><b-icon icon=""></b-icon>Total HT : {{billing.totalHT}} €</p>
                <p class="mb-1"><b-icon icon=""></b-icon>Total TVA :{{billing.totalTVA}} €</p>
                <p class="mb-1"><b-icon icon=""></b-icon><span class="font-weight-bold">Total TTC : {{billing.totalTTC}} €</span>  </p>
                <p class="mb-1"><b-icon icon=""></b-icon>Prime CEE : {{billing.prime_cee}}  €</p>
              </div>
            </div>

            <button type="button" @click="download('billing', billing.id)"  :disabled="loading" class="btn btn-outline-primary mr-2 mb-2"><b-spinner v-if="loading" small></b-spinner><b-icon icon="download"></b-icon> Télécharger</button>
          </div>
          <div v-else>
              Pas de factures
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col >
        <b-card title="Fidealis" class="mb-3">
            <button type="button" v-if="!fidealisUrl" @click="newFolder(contractId)"  :disabled="loading" class="btn btn-outline-primary mr-2 mb-2"><b-spinner v-if="loading" small></b-spinner><b-icon icon="folder"></b-icon> Nouveau dossier</button>
            <button type="button" v-else @click="openLink()"  class="btn btn-outline-primary mr-2 mb-2"><b-icon icon="box-arrow-up-right"></b-icon> Envoyer les photos</button>
            <button v-if="fileId" type="button" @click="download('fidealis', fileId)"  :disabled="loading" class="btn btn-outline-primary mr-2 mb-2"><b-spinner v-if="loading" small></b-spinner><b-icon icon="download"></b-icon> Télécharger</button>

        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col >
        <b-card title="Attestation sur l'honneur" class="mb-3">
          <button type="button" @click="download('ah', contractId)"  :disabled="loading" class="btn btn-outline-primary mr-2 mb-2"><b-spinner v-if="loading" small></b-spinner><b-icon icon="download"></b-icon> Télécharger</button>
          <button type="button" @click="signDocument('ah', contractId)"  :disabled="loading" class="btn btn-outline-primary mr-2 mb-2"><b-spinner v-if="loading" small></b-spinner><b-icon icon="vector-pen"></b-icon> Envoyer signature électronique</button>
          <button type="button" @click="download('proof', quotation.id)"  :disabled="loading" class="btn btn-outline-primary mr-2 mb-2"><b-spinner v-if="loading" small></b-spinner><b-icon icon="download"></b-icon> Preuve</button>
          <button type="button" @click="download('signed_ah', contractId, quotation.id)"  :disabled="loading" class="btn btn-outline-primary mr-2 mb-2"><b-spinner v-if="loading" small></b-spinner><b-icon icon="download"></b-icon> Document signé</button>
          <!-- <button type="button" @click="signDocument('initiator', contractId)"  :disabled="loading" class="btn btn-outline-primary mr-2"><b-spinner v-if="loading" small></b-spinner><b-icon icon="vector-pen"></b-icon> Envoyer signature électronique initiateur</button> -->

        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" v-show="true">
        <b-card title="Pre visite" class="mb-3">
            <button type="button" @click="download('premeeting', contractId)"  :disabled="loading" class="btn btn-outline-primary mr-2 mb-2"><b-spinner v-if="loading" small></b-spinner><b-icon icon="download"></b-icon> Télécharger</button>

        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" v-show="true">
        <b-card title="Fin de travaux" class="mb-3">
            <button type="button" @click="download('afterwork', contractId)"  :disabled="loading" class="btn btn-outline-primary mr-2 mb-2"><b-spinner v-if="loading" small></b-spinner><b-icon icon="download"></b-icon> Télécharger</button>

        </b-card>
      </b-col>
    </b-row>
  </b-container>

</template>

<script>
import ContractService from '../services/ContractService'
import GoBack from '../components/GoBack'
import DevisService from '../services/DevisService'
import BillingService from '../services/BillingService'
import FidealisService from '../services/FidealisService'
import YousignService from '../services/YousignService'
import ModalViewPDF from '../components/ModalViewPDF.vue'
import QuotationCard from '../components/QuotationCard.vue'

export default {
  name: 'MContractDetail',
  components: {
    GoBack,
    ModalViewPDF,
    QuotationCard
  },
  data: () => ({
    message : '',
    quotations: [],
    quotation : {},
    billings : [],
    billing : {},
    loading : false,
    contract : null,
    contracts: [],
    pdfStream: null,
    fidealisUrl: null,
    isFolderExists: null,
    fileId : null
  }),
  async mounted(){
    try {
      await this.getContract()  
      await this.ListContractQuotation()  
      await this.GetBilling() 
      await this.listDocument()
      await this.getFolder()
    } catch (error) {
      console.log(error)
    }

  },
  computed : {
    contractId () {
      return this.$route.params.contract_id | 0
    },
    customer() {
      return this.$store.getters.contract.customer
    },
  },
  watch: {
    
  },

  methods: {
    onSubmit (evt) {
      evt.preventDefault()
      alert(JSON.stringify(this.form))
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async getContract(){
      try {
        this.loading= true;
        let result = await ContractService.GetContract(this.contractId)
          this.loading = false;
          if(result.status != 200 || result.data.errors){

            throw new Error(result.data.errors)
          }

          this.totalRows = result.data.number_of_items
          this.contract = result.data.data
      } catch (error) {
        this.loading = false;
          this.$bvModal.msgBoxOk(`Impossible de charger le contrat : ${error.message}`, {
            title: 'Erreur',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
      }
  
    },
    async ListContractQuotation(){
      try {
        this.loading= true;
        let result = await DevisService.ListContractQuotation(this.$route.params.contract_id)
          this.loading = false;
          if(result.status == 200) {

            this.totalRows = result.data.number_of_items
            this.quotations = result.data.data.map(quotation => {
              return {
                    "id" : quotation.id,
                    dated_at : new Date(quotation.dated_at).toLocaleDateString('fr-FR'),
                    "reference" : quotation.reference,
                    "tva55" : 0,
                    "tva20" : 0,
                    "totalHT" : Number(quotation.total_sale_without_tax).toFixed(2),
                    "totalTVA" : (quotation.total_sale_with_tax - quotation.total_sale_without_tax).toFixed(2),
                    "totalTTC" : Number(quotation.total_sale_with_tax).toFixed(2),
                    "prime_cee" : Number(quotation.ite_prime).toFixed(2),
                    "is_signed" : quotation.is_signed == "YES" ? "Oui" : "Non",
                    "signed_at" : new Date(quotation.signed_at).toLocaleDateString('fr-FR'),
                  }
            })
            this.quotation = this.quotations.length > 0 ? this.quotations[0] : null
            console.log(this.quotations)
          }
      } catch (error) {
        this.loading = false;
          this.$bvModal.msgBoxOk(`Impossible de charger les devis : ${error.message}`, {
            title: 'Erreur',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
      }
  
    },
    async GetBilling(){
      try {
        this.loading= true;
        let result = await BillingService.GetBilling(this.$route.params.contract_id)
          this.loading = false;
          if(result.status == 200) {

            this.totalRows = result.data.number_of_items
            this.billings = result.data.data.map(billing => {
              return {
                    "id" : billing.id,
                    dated_at : new Date(billing.dated_at).toLocaleDateString('fr-FR'),
                    "reference" : billing.reference,
                    "tva55" : 0,
                    "tva20" : 0,
                    "totalHT" : Number(billing.total_sale_without_tax).toFixed(2),
                    "totalTVA" : (billing.total_sale_with_tax - billing.total_sale_without_tax).toFixed(2),
                    "totalTTC" : Number(billing.total_sale_with_tax).toFixed(2),
                    "prime_cee" : Number(billing.ite_prime).toFixed(2),
                  }
            })
            this.billing = this.billings.length > 0 ? this.billings[0] : null
          }
      } catch (error) {
        this.loading = false;
          this.$bvModal.msgBoxOk(`Impossible de charger les factures : ${error.message}`, {
            title: 'Erreur',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
      }
  
    },
    async CreateBilling(contractId, quotationId){
      try {
        this.loading= true;
        let result = await BillingService.CreateBilling(contractId, quotationId)
          this.loading = false;
          if(result.status != 200 || result.data.error){

            throw new Error(result.data.error)
          }
          await this.GetBilling()
      } catch (error) {
        this.loading = false;
          this.$bvModal.msgBoxOk(`Impossible de créer la facture : ${error.message}`, {
            title: 'Erreur',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
      }
  
    },
    async newFolder(contractId){
      try {
        this.loading= true;
        let result = await FidealisService.NewFolder(contractId)
          this.loading = false;
          if(result.status != 200 || result.data.error){

            if(result.data.error == 'No user exists') {
              throw new Error('Merci de spécifier un utilisateur dans iCall26')
            }
            else {
              throw new Error(result.data.error)
            }
          }

          this.fidealisUrl = result.data.url
      } catch (error) {
        this.loading = false;
          this.$bvModal.msgBoxOk(`Impossible de créer le dossier fidealis : ${error.message}`, {
            title: 'Erreur',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
      }
    },
    async listDocument(){
      try {
        this.loading= true;
        let result = await FidealisService.ListDocument(this.$route.params.contract_id)
          this.loading = false;
          if(result.status != 200 || result.data.error || result.data.errors){

            throw new Error(JSON.stringify(result.data.errors))
          }
          else {

            const res = result.data.data.filter(x=> x.extension == 'pdf')

            if(res.length > 0){
              this.fileId = res[0].id
            }
          }

      } catch (error) {
        this.loading = false;
          this.$bvModal.msgBoxOk(`Impossible d'afficher la liste des fichiers fidealis : ${error.message}`, {
            title: 'Erreur',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
      }
    },
    async getFolder(){
      try {
        this.loading= true;
        let result = await FidealisService.GetFolder(this.$route.params.contract_id)
          this.loading = false;
          if(result.status != 200 || result.data.error){

            if(result.data.error == "Folder is invalid"){
              this.isFolderExists = false
            }
            else {
              throw new Error(result.data.error)
            }
          }
          else {
            this.fidealisUrl  = result.data.data.url
          }

          

      } catch (error) {
        this.loading = false;
          this.$bvModal.msgBoxOk(`Impossible de recuperer le dossier fidealis : ${error.message}`, {
            title: 'Erreur',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
      }
    },
    openLink() {
      window.open(this.fidealisUrl, 'blank')
    },
    async signDocument(type,id) {

      try {
        this.message = ""
        this.loading = true
        let result = null


        switch (type) {
          case "quotation":
            result = await YousignService.EnvoiSignatureElectroniqueQuotation(id) // quotationId
            break;
          case "ah":
            result = await YousignService.EnvoiSignatureElectronique(id) // contractId
            break;
          case "initiator":
            result = await YousignService.EnvoiSignatureElectroniqueInitiator(id) // contractId
            break;
          default:
            break;
        }
        this.loading = false

        if(result.status != 200 || result.data.errors) {
          this.$bvModal.msgBoxOk(`Impossible d'envoyer le document en signature électronique' : ${JSON.stringify(result.data.errors)}`, {
            title: 'Erreur',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
          return
        }   

        this.$bvModal.msgBoxOk(`Document envoye en signature électronique avec succes`, {
            title: 'Confirmation',
            okVariant: 'success',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
    
        } catch (error) {
          this.loading = false
          this.$bvModal.msgBoxOk(`Impossible d'envoyer le document en signature électronique : ${error.message}`, {
              title: 'Erreur',
              okVariant: 'danger',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
        }
    },
    async download(type, id, id2) {

      try {
        this.message = ""
        this.loading = true
        let result = null
        
        switch (type) {
          case "quotation":
            result = await DevisService.ExportPdfDevis(id) // quotationId
            break;
          case "billing":
            result = await BillingService.ExportPdfBilling(id) // contractId
            break;
          case "ah":
            result = await ContractService.ExportDocumentPdf(id) // contractId
            break;
          case "premeeting":
            result = await ContractService.ExportPdfPremeeting(id) // contractId
            break;
          case "afterwork":
            result = await ContractService.ExportPdfAfterWork(id) // contractId
            break;
          case "fidealis":
            result = await FidealisService.ExportPdf(id) // fileId
            break;
          case "signed":
            result = await YousignService.ExportPdfDevisSigne(id, id2) // contractId, quotationId
            break;
          case "signed_ah":
            result = await YousignService.ExportPdfAHSigne(id, id2) // contractId, quotationId
            break;
          case "proof":
            result = await YousignService.ExportProofPdfFile(id, null) // fileId
              break;
          default:
            break;
        }

        this.loading = false


        if(result.status != 200 || result.data.errors) {
          this.$bvModal.msgBoxOk(`Impossible de télécharger le document : ${JSON.stringify(result.data.errors)}`, {
            title: 'Erreur',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
          return
        }   

        window.open(URL.createObjectURL(new Blob([result.data], { type: 'application/pdf' })))
        //this.$bvModal.show('modal-confirm')
          
        } catch (error) {
          this.loading = false
          this.$bvModal.msgBoxOk(`Impossible de visualiser le document : ${error.message}`, {
              title: 'Erreur',
              okVariant: 'danger',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
      }
    }, 
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
/* ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
} */
legend {
  font-size: 16px;
}

.contact-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .contact-section {
      flex: 1;
      margin-right: 20px; /* Espacement entre les sections */
    }

    .contact-section:last-child {
      margin-right: 0; /* Supprime la marge à droite de la dernière section */
    }

    /* Style pour les étiquettes */
    .label {
      font-weight: bold;
    }


</style>
