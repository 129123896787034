<template>

      <b-modal :id="id" size="xl" :title="title" button-size="sm">
        <div class="d-flex justify-content-between">
          <span>{{currentPage}} / {{ pageCount }}</span>
          <b-button-group>
            <b-button @click="currentPage > 1 && currentPage <= pageCount ? currentPage-- : 0" > - </b-button>
            <b-button @click="currentPage >= 1 && currentPage < pageCount ? currentPage++ : 0" > + </b-button>
          </b-button-group>
        </div>
      <pdf 
        :src="src"
        @num-pages="pageCount = $event"
        @page-loaded="currentPage = $event"
        :page="currentPage"
      ></pdf>
      <template #modal-footer>
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <b-button size="sm" variant="success" @click="currentPage=1;$bvModal.hide(`${id}`)">
        Fermer
      </b-button>
    </template>
    </b-modal>

</template>
<script>
 import pdf from 'vue-pdf'
  
export default {
  name: 'ModalViewPDF',
  components: {
    pdf
  },
  props: {
    title : {
      type: String
    },
    id : {
      type: String
    },
    src : {
      type : String
    }
  },
  data: () => ({
    pageCount : null, 
    currentPage : 1
  }),
  methods: {
  }
}
</script>