import Api from '@/services/Api'
import authHeader from './Auth-header';

function addDaysToDate(n) {
	let currentDate = new Date();
	currentDate.setDate(currentDate.getDate() + n);
	return currentDate;
  }
  

async function ListContract(beginDate, endDate, signed){

	let form = new FormData();

	form.append('filter[range][opened_at][from]', new Date( beginDate).toLocaleDateString('fr-FR'));
	form.append('filter[range][opened_at][to]', new Date( endDate).toLocaleDateString('fr-FR')); 
	
	switch (signed) {
		case true:
			form.append('filter[equal][document_evidence_is_signed]',"YES"); 
			break;
		case false:
			form.append('filter[equal][document_evidence_is_signed]',"NO"); 
			break;
		default:
			break;
	}

	const r = Api.post(`/admin/api/v2/customers/contracts/admin/ListContract?token=${authHeader()}`,form)
	return r
}

async function GetContract(contractId){

	const r = Api.get(`/admin/api/v2/customers/contracts/admin/GetContract?token=${authHeader()}&id=${contractId}`)
	return r
}

async function ExportPdfPremeeting(contractId){

	const r = Api.get(`/admin/api/v2/applications/iso/admin/ExportPreMeetingDocumentPdf?token=${authHeader()}&contract=${contractId}`, { responseType: 'blob' })
	return r
}

async function ExportPdfAfterWork(contractId){

	const r = Api.get(`admin/api/v2/applications/iso/admin/ExportAfterWorkDocumentPdf?token=${authHeader()}&contract=${contractId}`, { responseType: 'blob' })
	return r
}

async function ExportDocumentPdf(contractId){

	const r = Api.get(`admin/api/v2/applications/iso3/admin/ExportDocumentPdf?token=${authHeader()}&contract=${contractId}`, { responseType: 'blob' })
	return r
}

async function NewContract(data){

	let form = new FormData();

	const token = authHeader()

	form.append('customer[gender]', data.customer.gender);
	form.append('customer[firstname]', data.customer.firstname); 
	form.append('customer[lastname]', data.customer.lastname); 
	form.append('customer[phone]', data.customer.phone); 
	form.append('customer[mobile]', data.customer.mobile); 
	form.append('customer[email]', data.customer.email); 
	form.append('address[address1]', data.address.address1); 
	form.append('address[postcode]', data.address.postcode); 
	form.append('address[city]', data.address.city); 
	form.append("contract[polluter_id]", data.polluter_id);
	form.append("iso2[revenue]", data.revenue);
	form.append("iso2[energy_id]", data.energy_id);
	form.append("iso2[occupation_id]", data.occupation_id);
	form.append("iso2[more_2_years]", data.more_2_years);
	form.append("iso2[layer_type_id]", data.layer_type_id);
	form.append("iso2[pricing_id]", data.pricing_id);
	form.append("iso2[number_of_people]", 1);
	form.append("iso2[number_of_parts]", 1);
	form.append("iso2[declarants]", 1);
	form.append("iso2[number_of_fiscal]", 1);
	form.append("iso2[surface_home]", 1);
	form.append("iso2[surface_ite]", 1);
	form.append("iso2[parcel_surface]", 1);
	
	form.append('attributions[team_id]', data.team_id); 
	form.append('attributions[contributors][telepro][attribution_id]', data.telepro_id); 
	form.append('attributions[contributors][sale_2][attribution_id]', data.sale_2_id); 
	form.append('attributions[contributors][sale_1][attribution_id]', data.sale_1_id); 
	form.append('attributions[contributors][manager][attribution_id]', data.manager_id); 

	
	form.append('contract[opened_at]', addDaysToDate(16).toLocaleDateString('fr-FR')); 
	form.append('contract[opc_at]', addDaysToDate(0).toLocaleDateString('fr-FR')); 
	form.append('contract[sav_at]', addDaysToDate(0).toLocaleDateString('fr-FR')); 
	form.append('contract[quoted_at]', addDaysToDate(15).toLocaleDateString('fr-FR')); 
	form.append('contract[billing_at]', addDaysToDate(30).toLocaleDateString('fr-FR')); 




	return Api.post(`/admin/api/v2/customers/contracts/admin/NewContract?token=${token}`, form)
}

async function UpdateContract(data){

	let form = new FormData();

	const token = authHeader()

	form.append('id', data.id);
	form.append('customer[gender]', data.customer.gender);
	form.append('customer[firstname]', data.customer.firstname); 
	form.append('customer[lastname]', data.customer.lastname); 
	form.append('customer[phone]', data.customer.phone); 
	form.append('customer[mobile]', data.customer.mobile); 
	form.append('customer[email]', data.customer.email); 
	form.append('address[address1]', data.address.address1); 
	form.append('address[postcode]', data.address.postcode); 
	form.append('address[city]', data.address.city); 
	form.append("polluter_id", data.polluter_id);
	form.append("iso2[revenue]", data.revenue);
	form.append("iso2[energy_id]", data.energy_id);
	form.append("iso2[occupation_id]", data.occupation_id);
	form.append("iso2[more_2_years]", data.more_2_years);
	form.append("iso2[layer_type_id]", data.layer_type_id);
	form.append("iso2[number_of_people]", data.number_of_people);
	form.append("iso2[number_of_parts]", data.number_of_parts);
	form.append("iso2[declarants]", data.declarants);
	form.append("iso2[number_of_fiscal]", data.number_of_fiscal);
	form.append("iso2[surface_home]", data.surface_home);
	form.append("iso2[surface_ite]", data.surface_ite);
	//form.append("iso2[parcel_surface]", 1);

	form.append('pre_meeting_at[date]', new Date(data.pre_meeting_at_date).toLocaleDateString('fr-FR')); 
	form.append('pre_meeting_at[hour]', data.pre_meeting_at_hour); 
	form.append('pre_meeting_at[minute]', data.pre_meeting_at_minute); 
	form.append('opened_at', new Date(data.opened_at).toLocaleDateString('fr-FR')); 
	form.append('opc_at', new Date(data.opc_at).toLocaleDateString('fr-FR')); 
	form.append('sav_at', new Date(data.sav_at).toLocaleDateString('fr-FR')); 
	form.append('quoted_at', new Date(data.quoted_at).toLocaleDateString('fr-FR')); 
	form.append('billing_at', new Date(data.billing_at).toLocaleDateString('fr-FR')); 
	// form.append('verif',""); 

	return Api.post(`/admin/api/v2/customers/contracts/admin/UpdateContract?token=${token}`, form)
}


export default {
	ListContract, NewContract, UpdateContract, GetContract, ExportPdfPremeeting, ExportPdfAfterWork, ExportDocumentPdf
}