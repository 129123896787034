<template>
<div>
    <b-container fluid="sm" class="mt-5 p-4 bg-primary">
        <b-card no-body class="overflow-hidden align-center">
            <b-card-body>

              <b-row>
                <b-col md="6">
                    <b-img center thumbnail fluid :src="pic()" alt="Image 1"></b-img>
                </b-col>
                <b-col>
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(login)" >

                <b-alert variant="danger" :show="message != ''" class="mt-3 mb-3">{{message}}</b-alert>
                <validation-provider name="URL CRM" :rules="{ required: true, min: 3 }" v-slot="validationContext" >
                    <b-form-group id="url-grp" label="URL CRM" label-for="login">

                      <b-input-group size="lg" prepend="https://" append=".icall26.net">
                        <b-form-input id="url" type="text" v-model="crm" trim
                        :state="getValidationState(validationContext)" aria-describedby="crm-fb"
                        list="crm-list-id">
                      </b-form-input>
                      <datalist id="crm-list-id">
                        <option v-for="(crm, index) in crmList" :key="index">{{ crm }}</option>
                      </datalist>
                      <b-form-invalid-feedback id="crm-fb">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
  </b-input-group>
                       
                        
                    </b-form-group>
                </validation-provider>
                <validation-provider name="Identifiant" :rules="{ required: true, min: 3 }" v-slot="validationContext" >
                    <b-form-group id="login-grp" label="Identifiant" label-for="login">
                        <b-form-input id="login" type="text" v-model="user.username" trim
                        :state="getValidationState(validationContext)" aria-describedby="login-fb"></b-form-input>
                        <b-form-invalid-feedback id="login-fb">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
                <validation-provider name="Mot de passe" :rules="{ required: true, min: 3 }" v-slot="validationContext" >
                    <b-form-group id="password-grp" label="Mot de passe" label-for="password">
                        <b-form-input id="password" type="password" v-model="user.password" trim
                        :state="getValidationState(validationContext)" aria-describedby="password-fb"></b-form-input>
                        <b-form-invalid-feedback id="password-fb">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
                <b-button type="submit" variant='primary'>
                    <b-spinner v-if="loading" small type="border"></b-spinner>
                    Connecter</b-button>
            </b-form>
        </validation-observer>
    </b-col>
              </b-row>
        </b-card-body>

  </b-card>
</b-container>
</div>
  
</template>

<script>
import User from '../models/user';

export default {
  name: 'MyLogin',
  data :() => ({
    user: new User('', '',''),
    loading: false,
    message: '',
    crmList : [],
    crm:""
  }),
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  mounted(){
    if(localStorage.getItem("crmList")) {
      this.crmList = JSON.parse(localStorage.getItem("crmList"))
    }
  },
  methods: {
      pic() {
        //return "https://picsum.photos/400/400/?image=" + i
        return require('../assets/logo-horizontal-vecto.png')
      },
    login() {
        this.loading = true;
        this.$store.dispatch('login', {user :this.user, crm:`https://${this.crm}.icall26.net`}).then(
            () => {
                //save CRM into local storage
                if (!localStorage.getItem("crmList")) {
                    // Si la clé n'existe pas, sauvegarde la valeur dans le local storage
                    this.crmList.push(this.crm)
                    localStorage.setItem("crmList", JSON.stringify(this.crmList));
                } else {
                  let list = JSON.parse(localStorage.getItem("crmList"))
                    if(!list.includes(this.crm)) {
                      list.push(this.crm)
                      localStorage.setItem("crmList", JSON.stringify(list));
                    }
                }

                this.$store.dispatch('setCRM',this.crm)

                //redirect to clients
                this.$router.push('/meetings');
            },
            error => {
                this.loading = false;
                if(error.response.status == 403) {
                  this.message = 'Login ou mot de passe incorrect'
                }
                else {
                  this.message = 'Erreur'
                }
            }
        )
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onSubmit (evt) {
      evt.preventDefault()
      alert(JSON.stringify(this.form))
    }
  }
};
</script>

<style>
body {
    background-color: rgba(140, 138, 152, 0) !important;
}

</style>