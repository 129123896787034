import LocalApi from './LocalApi';
import Api,{ updateBaseURL} from '@/services/Api';

async function fetchModelVT () {
    return LocalApi().get('/admin/model');
}

async function updateModelVT (model) {
    return LocalApi().post('/admin/model',model);
}

async function fetchRules () {
    return LocalApi().get('/admin/rules');
}

async function saveRules (rules) {
    return LocalApi().post('/admin/rules',rules);
}


  function setCookie(cname, cvalue, exdays) {
	const d = new Date();
	d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
	let expires = "expires="+d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  
  function deleteCookie(cname) {
	document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }



async function SignIn(user, crm) {
	try {

		localStorage.setItem('crm', crm)
		console.log(crm)
		updateBaseURL(crm)
		let result = await Api.get(`/admin/api/users/guard/admin/Signin?username=${user.username}&password=${user.password}`)
		if(result.status === 200) {
			if(result.data.error) { 
				return Promise.reject({response :{status : 403, message : result.data.error}});
			}

			setCookie("admin_app", result.data.token, 7)
		}		

		return Promise.resolve(result.data);
	} catch (error) {
		return Promise.reject(error);
	}

}

async function SignOut() { 
	deleteCookie("admin_app")
}

export default {
	SignIn,SignOut, fetchModelVT, fetchRules, saveRules, updateModelVT
}