
export default {
  methods: {
    dialogError(message, error) {
      return  this.$bvModal.msgBoxOk(`${message} : ${error}`, {
        title: 'Erreur',
        okVariant: 'danger',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
    },
    dialogSuccess(message) {
      return this.$bvModal.msgBoxOk(`${message}`, {
        title: 'Confirmation',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
    }
  },
};