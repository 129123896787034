<template>
    <div>
        <h3>Regles</h3>

        <div class="d-flex flex-row mb-2  justify-content-end">
            <b-button @click="openAdd()" class="d-flex justify-content-end" >Nouvelle regle <b-icon icon="plus" variant="outline-dark"></b-icon></b-button>
        </div>

        <b-skeleton-wrapper :loading="loading">
            <template #loading>
                <b-card>
                <b-skeleton width="85%"></b-skeleton>
                <b-skeleton width="55%"></b-skeleton>
                <b-skeleton width="70%"></b-skeleton>
                </b-card>
            </template>


            <b-list-group>
                <b-list-group-item v-for="(rule,index) in rules" :key="index" class="flex-column align-items-start">
                    <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">{{rule.label}}</h5>
                        <b-button-toolbar>
                            <b-button-group class="mx-1" size="sm">
                            <b-button @click="openEdit(rule)"><b-icon icon="pencil"></b-icon></b-button>
                            <b-button @click="openDelete(index)"><b-icon icon="trash"></b-icon></b-button>
                            </b-button-group>
                        </b-button-toolbar>
                    </div>

                    <h6>Conditions : </h6>
                    <p class="mb-2">
                        {{ rule.formatConditions }}
                    </p>

                    <h6>Produit :</h6>
                    <p class="mb-2">
                        {{ productTitle(rule.productId)}}
                    </p>
                </b-list-group-item>   
            </b-list-group>
       
    </b-skeleton-wrapper>

        <b-modal :title="modalTitle" scrollable :id="`modal-rule`" button-size="sm" size="lg">
            <b-form>
                
                <b-form-row>
                    <b-col>
                        <b-form-group id="input-group-1" label="Titre:" label-for="input-1">
                            <b-form-input id="input-1" v-model="currentRule.label" type="text" required></b-form-input>
                        </b-form-group> 
                    </b-col>
                </b-form-row>
                
                <b-form-row>
                    <b-col>
                        <b-form-group id="select-rub" label="Si Rubrique:" label-for="rub">
                            <b-form-select id="rub" v-model="currentRule.rubId" :options="rubriques" value-field="id" text-field="title" @change="selectQuestions" ></b-form-select>
                        </b-form-group>
                    </b-col>
                </b-form-row>
                

                <label>Et Conditions:</label>
                <ConditionList
                    :questions="questions" 
                    :conditions="currentRule.conditions"
                    :rubId="currentRule.rubId"
                    @add-condition="addCondition"
                    @remove-condition="removeCondition"
                >
                </ConditionList>

                <b-form-row>
                    <b-col>
                        <b-form-group id="select-article" label="Alors Produit:" label-for="article">
                            <b-form-select id="article" v-model="currentRule.productId" :options="articles" value-field="id" text-field="title" ></b-form-select>
                        </b-form-group>
                    </b-col>
                </b-form-row>
            </b-form>


            <template #modal-footer="{cancel}">
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button size="sm" variant="success" @click="save()">
                    Enregistrer
                </b-button>
                <b-button size="sm" variant="danger" @click="cancel()">
                    Annuler
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>

import IcallService from '../services/IcallService'
import CatalogService from '../services/CatalogService';
import ConditionList from '../components/ConditionList.vue'
import Rule from '../models/rule'
import Condition from '../models/condition';

export default {
    name: 'MCRMManager',
    components: {
        ConditionList
    },
    data : () => ({
        loading: false,       
        blocs : [],
        rubId : 0,
        rubriques : [],
        questionId : 0,
        questions : [],
        filtreQuestions: [],
        list_values: [],
        articles : [],
        articleId : "",
        value : "",
        operator :"",
        operators : ["==", "!="],
        currentRule : {},
        message: '',
        modalTitle: "Nouveau",
        conditions : []
        
    }),
    async mounted(){


        try {
            this.loading = true
            const result = await CatalogService.GetArticles()
            this.loading = false
            if(result.status == 200) {
                this.articles = result.data.data.map(article => { return {id : article.id, title : `[${article.id}] - ${article.reference}`}})
            }
        } catch (error) {
            this.loading = false
            this.$bvModal.msgBoxOk(`Impossible de charger les articles : ${error.message}`, {
            title: 'Erreur',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
        }
        

        this.blocs = this.$store.getters.modelVT.blocs
        this.rubriques = this.blocs.map(rub => { return {id : rub.id, title : rub.title}})

        this.rules.forEach(rule => {
            rule.conditions = this.parseCondition(rule.condition);
            rule.formatConditions = this.formatCondition(rule.conditions)
        });

        this.questions = []
        this.blocs.forEach(rub => { 
            if(rub.questions) {
                //console.log(rub.questions)
                rub.questions.forEach(question => {
                    this.questions.push({
                        rubId : rub.id, 
                        id : question.id, 
                        title : question.title, 
                        list_values: question.list_values, 
                        question_type: question.question_type,
                        key : question.comment
                    })
                    if(question.sub_questions) {
                        console.log(question.sub_questions)
                        question.sub_questions.forEach(question => {
                            this.questions.push({
                                rubId : rub.id, 
                                id : question.id, 
                                title : question.title, 
                                list_values: question.list_values, 
                                question_type: question.question_type,
                                key : question.comment,
                            }) 
                            
                            if(question.sub_questions) {
                                console.log(question.sub_questions)
                                question.sub_questions.forEach(question => {
                                    this.questions.push({
                                        rubId : rub.id, 
                                        id : question.id, 
                                        title : question.title, 
                                        list_values: question.list_values, 
                                        question_type: question.question_type,
                                        key : question.comment,
                                    })                
                                })
                            }
                        })
                    }
                })
            }
        })
    },
    computed: {
        rules() {
            return this.$store.getters.rules
        },
        modelVT() {
            return this.$store.getters.modelVT
        },
        
    },
    methods: {
        productTitle(productId) {
            const product = this.articles.find(x=> x.id == productId)
            return product ? product.title : ""
        },
        parseCondition(condition) {
            // Les opérateurs possibles
            const operators = ["==", "!=", ">", ">=", "<", "<="];

            // Fonction pour échapper les opérateurs dans une regex
            function escapeRegExp(string) {
                return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            }

            // Construire une regex pour tous les opérateurs
            const operatorsRegex = operators.map(escapeRegExp).join('|');
            const regex = new RegExp(`(\\w+)\\s*(${operatorsRegex})\\s*['"]([^'"]+)['"]\\s*(?:&|AND|\\||OR)?`, 'g');

            let match;
            let result = [];

            // Analyser chaque correspondance dans la chaîne de condition
            while ((match = regex.exec(condition)) !== null) {
                result.push({
                    key: match[1],
                    operator: match[2],
                    value: match[3]
                });
            }

            return result;
        },
        addCondition() {
            console.log('addCondition')
            this.currentRule.conditions.push(new Condition())
        },
        removeCondition(index) {
            console.log('removeCondition')
            this.currentRule.conditions.splice(index, 1);
        },
        formatCondition(conditions) {
            let res =""
            let op = ""
            conditions.forEach(condition => {
                if(res.length> 0)
                    op = " et "
                res = res + `${op}${this.modelVT.getTitleByRef(condition.key)} ${condition.operator} ${condition.value}`
            });
            return res
        },
        selectQuestions() {
            this.filtreQuestions = this.questions.filter(q=>  q.rubId == this.rubId)
        },
        selectValue() {
            this.list_values = this.questions.find(q=> q.id == this.questionId).list_values
        },
        getNextId(rules) {
            const ids = rules.map(rule => rule.id);
            const maxId = Math.max(...ids);
            return maxId + 1;
        },
        openAdd() {

            console.log("OpenAdd Bloc")
            //
            this.modalTitle="Nouveau"
            this.currentRule = new Rule()

            this.$bvModal.show(`modal-rule`)

        },
        openEdit(rule) {
            this.modalTitle="Modifier"
            this.currentRule = { ...rule }
            this.rubId = this.currentRule.rubId
			this.selectQuestions()
		
            this.$bvModal.show(`modal-rule`)
        },
        openDelete(index) {
            this.$bvModal.msgBoxConfirm('Voulez vous supprimer cette regle ?', {
                title: 'Confirmation',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Oui',
                cancelTitle: 'Non',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                console.log(value)
                if(value) {
                    console.log(index)
                    this.rules.splice(index, 1)
                    console.log("Call save api")
                    this.save()
                }
            })
            .catch(err => {
                // An error occurred
                console.log(err)
            })
        },
        async save() {
            if(this.currentRule.formatConditions) {
                this.currentRule.formatConditions = this.formatCondition(this.currentRule.conditions)
                if(this.currentRule.id == -1) {
                    this.currentRule.id = this.getNextId(this.rules)
                    this.rules.push(this.currentRule)
                }else {
                    const index = this.rules.findIndex(rule => rule.id === this.currentRule.id);
                    if (index !== -1) {
                        this.$set(this.rules, index, this.currentRule);
                    }
                }

                this.currentRule = {}
            }
            console.log('Call local api')
            console.log(this.rules)
            try {
                this.loading = true
                const result =await IcallService.saveRules(this.rules)
                this.loading = false
                if(result.status == 201) {
                    this.$bvModal.msgBoxOk('Règles enregistrées avec succès', {
                    title: 'Confirmation',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                    })
                    this.$store.dispatch('setRules',this.rules)
                }

            } catch (error) {
                this.loading = false
                this.$bvModal.msgBoxOk(`Impossible de sauvegarder les regles : ${error.message}`, {
                    title: 'Erreur',
                    okVariant: 'danger',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                })
            }
            this.$bvModal.hide(`modal-rule`)
            
        },
        cancel() {
            console.log("cancel")
            this.currentRule.rule = {}
        }
    }
};
</script>